import React, {
  useState,
  Fragment,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { connect } from "react-redux";
import Button from "../../shared/UI/Button/Button";
import "./HostStudioObject.css";
import "../../../assets/overrides/ant-overrides.css";
import * as actions from "../../../store/actions/index";
import {
  removeDuplicates,
  hasValidPath,
  getPathClassName,
  splitSignalAndPhone,
  getActiveSipClass,
  getSipLabel,
  getSipPill,
  getSummaryRow,
  parseDate,
  trimLabel,
} from "../../shared/utility";
import Datepicker from "react-datepicker";
import HostIcon from "../../../assets/icons/host-icon.svg";
import { ReactComponent as ExtendPermIcon } from "../../../assets/icons/extend-perm-icon.svg";
import StudioIcon from "../../../assets/icons/studio-icon.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar-expdate.svg";
import { ReactComponent as GrabberIcon } from "../../../assets/icons/liveshot-grabber.svg";
import * as Constants from "../../shared/Constants";
import ErrorMessage from "../../shared/ErrorMessage";
import Backdrop from "../../shared/UI/Backdrop/Backdrop";
import StudioTruck from "../../studiotruck/StudioTruck";
import LiveShotSignals from "../liveshotsignals/LiveShotSignals";
import Tooltip from "../../shared/UI/Tooltip/Tooltip";
import LiveshotNameSuggestion from "../LiveshotNameSuggestion/LiveshotNameSuggestion";
import DuplicateLiveshot from "../duplicateLiveshot/DuplicateLiveshot";
import CommanModal from "../../shared/UI/commanModal/CommanModal";
import CommPills from "../../pills/CommPills/CommPills";
import OutBoundManagePills from "../../pills/OutBoundManagePills/OutBoundManagePills";
import { ReactComponent as InfoLiveShotLogo } from "../../../assets/icons/Info-liveshots.svg";
import globalSatelliteIcon from "../../../assets/icons/global-satellite-icon.svg";
import ExtendLogo from "../../../assets/icons/Extend-Date.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/Trash.svg";
import { ReactComponent as UpIcon } from "../../../assets/icons/liveshot-up.svg";
import { ReactComponent as DownIcon } from "../../../assets/icons/liveshot-down.svg";
import { ReactComponent as CollapseUpIcon } from "../../../assets/icons/expand-ls-up.svg";
import { ReactComponent as CollapseDownIcon } from "../../../assets/icons/expand-ls-down.svg";
import Warning_icon from "../../../assets/icons/Warning.svg";
import Warning_icon_red from "../../../assets/icons/warning-red.svg";
import { DebounceInput } from "react-debounce-input";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import {
  liveshot_canvas_constants,
  DELETE_LIVESHOT_MESSAGE,
} from "../../../constants/PageConstants";
import SelectDropdown from "../../shared/UI/Select/StatusDropdown";
import { checkPermission } from "../../shared/utils/userUtils";
import { useUser } from "../../../hooks/useUser";
import { ArrowTooltip } from "../../shared/UI/Tooltip/ArrowTooltip";
import { ConfirmationModal } from "../../shared/UI/ConfirmationModal/ConfirmationModalPopup";
import { VENUE_TYPES } from "../../../constants/PageConstants";
import Loader from "../../shared/UI/Loader/Loader";
import PopupModal from "../../../components/shared/UI/common/Modal";
import { ReactComponent as Up } from "../../../assets/icons/overlay-up.svg";
import { ReactComponent as Down } from "../../../assets/icons/overlay-down.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user-icon.svg";
import LabelledCheckbox from "../../shared/UI/LabelledCheckbox/LabelledCheckbox";
import { AntdTooltip } from "../../shared/UI/Tooltip/AntdTooltip";

const HostStudioObject = (props) => {
  const {
    updateLiveshotPerm,
    updateLiveshotStatusPerm,
    takeAllPerm,
    grabAllPerm,
    createSignalPerm,
    deleteLiveshotPerm,
    manageLiveshotSignalPerm,
  } = useUser();
  const {
    onPatchStudioTruck,
    formInfo,
    currentCanvasId,
    currentCanvas,
    routingCapabilityType,
    onUpdateLiveShotOnChange,
    onUpdateStatus,
    onSubmitAbstractLiveshot,
    id,
    token,
    newliveshot,
    type,
    successMsg,
    studioTruckData,
    studioTruckList,
    currentUser,
    liveShotSignalSelected,
    onDeleteLiveShot,
    showErrorModal,
    blockId,
    onTakeAll,
    pinModalInfo,
    onGrabAll,
    grabbedSignals,
    userList,
    currentUserRole,
    pressedKey,
    cityListData,
    onHideSignalErrorModal,
    signalErrorMsg,
    errorMsg,
    normalLiveShots,
    onHideErrorModal,
    showSignalErrorModal,
    globalPins,
    index,
    controlRoomList,
    fetchPinModalInfo,
    isArmRouting,
    activeLiveshot,
    onLiveshotUpdate,
    inBoundCRPath,
    inBoundGlobalPath,
    outBoundCRPath,
    outBoundGlobalPath,
    inBoundExtendGlobalPath,
    ifbGlobalPath,
    plGlobalPath,
    phoGlobalPath,
    phoTxGlobalPath,
    sharedInBoundGlobalPath,
    sharedOutBoundGlobalPath,
    sharedIFBGlobalPath,
    sharedPLGlobalPath,
    sharedPHOTXGlobalPath,
    redBorder,
    ifbCRPath,
    plCRPath,
    isBlockLiveshot,
    phoCRPath,
    phoTxCRPath,
    availSignal,
    routerDestinations,
    nextActiveLiveshot,
    onSetAccessPin,
    pinError,
    onResetPinError,
    pinTableInfo,
    isNewShotAdded,
    blockPerm,
    onDropSip,
    sipState,
    formattedRouterSource,
    resetSignals,
    onResetStudioTruckData,
    showCommonNotificationBar,
    loading,
    onResetAddedFromSearch,
    canvasTemplateSelected,
  } = props;
  const [isDisable, setIsDisable] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [location, setLocation] = useState("");
  const [liveshotName, setLiveshotName] = useState("");
  const [liveshotNotes, setLiveshotNotes] = useState("");
  const [hitTime, setHitTime] = useState(
    formInfo ? formInfo.abstractLiveshot.hitInterval : ""
  );
  const [poNo, setPoNo] = useState("");
  const [hitNote, setHitNote] = useState("");
  const [liveshotTypeId, setLiveshotTypeId] = useState(type);
  const [stId, setStId] = useState("");
  const [windowStartTime, setWindowStartTime] = useState(
    formInfo.abstractLiveshot.windowStartTime
  );
  const [windowEndTime, setWindowEndTime] = useState(
    formInfo.abstractLiveshot.windowStartTime
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [liveShotInfoLabel, setLiveShotInfoLabel] = useState(true);
  const [expObj, setExpObj] = useState(true);
  const [liveShotInfoWrap, setLiveShotInfoWrap] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMessage] = useState(false);
  // eslint-disable-next-line
  const [formatted_date, setFormatted_date] = useState(
    formInfo && formInfo.abstractLiveshot.expiration
      ? new Date(formInfo.abstractLiveshot.expiration)
          .toISOString()
          .split(".")[0] + "Z"
      : null
  );
  const [expiration, setExpiration] = useState(null);
  const [isPermanentLiveshot, setIsPermanentLiveshot] = useState(false);
  const [venueName, setVenueName] = useState("");
  const [studioDefaultTransmissionPath, setStudioDefaultTransmissionPath] =
    useState("");
  const [venueContact, setVenueContact] = useState("");
  const [venuePhone, setVenuePhone] = useState("");
  const [venueEmail, setVenueEmail] = useState("");
  const [showVenue, setShowVenue] = useState(false);
  const [venueType, setVenueType] = useState("");
  const [tempVType, setTempVType] = useState(
    "E.G. 30R LOCATION, SKYPE, QUICKLINK..."
  );
  const [venueNotes, setVenueNotes] = useState("");
  const [venueCost, setVenueCost] = useState("");
  const [studioLocation, setStudioLocation] = useState("");
  const [venueCity, setVenueCity] = useState("");
  const [venueRegion, setVenueRegion] = useState("");
  const [venueCountry, setVenueCountry] = useState("");
  const [venueSkype, setVenueSkype] = useState("");
  const [venueQuicklink, setVenueQuicklink] = useState("");
  const [venueWebOther, setVenueWebOther] = useState("");
  const [validHitTime, setValidHitTime] = useState(false);
  const [validStartTime, setValidStartTime] = useState(false);
  const [validEndTime, setValidEndTime] = useState(false);
  const [record, setRecord] = useState(false);
  const [userName, setUserName] = useState();
  const [abstractUserName, setAbstractUserName] = useState();
  const [showsuggestion, setShowSuggestion] = useState(false);
  const [showcitysuggestion, setShowCitySuggestion] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [roomCore, setRoomCore] = useState(null);
  const [isReadyPcr, setIsReadyPcr] = useState(false);
  const [enterCount, setEnterCount] = useState(0);
  const activeSlideRef = useRef(null);
  const [studioIFB, setStudioIFB] = useState("");
  const [studioPL, setStudioPL] = useState("");
  const [studioPHO, setStudioPHO] = useState("");
  const [stTypeId, setStTypeId] = useState(0);
  const [stype, setStype] = useState(stTypeId);
  const [ifbPin, setIfbPin] = useState("");
  const [plPin, setPlPin] = useState("");
  const [phoPin, setPhoPin] = useState("");
  const [firstIfbGlobalPath, setFirstIfbGlobalPath] = useState(null);
  const [firstPlGlobalPath, setFirstPlGlobalPath] = useState(null);
  const [firstPhoGlobalPath, setFirstPhoGlobalPath] = useState(null);
  const [defaultCommSignals, setDefaultCommSignals] = useState(null);
  const [previousLSstatus, setPreviousLSstatus] = useState("");
  const [noAvailVcModal, setNoAvailVcModal] = useState(null);
  const [clearLSWarningModal, setClearLSWarningModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [clearLSWarningYesOrNo, setClearLSWarningYesOrNo] = useState(null);
  const [lsStatusPreviousClear, setLsStatusPreviousClear] = useState(false);
  const [inBoundFields, setInBoundFields] = useState([]);
  const [inBoundFieldsNormal, setInBoundFieldsNormal] = useState([]);
  const [outBoundFields, setOutBoundFields] = useState([]);
  const [outBoundFieldsNormal, setOutBoundFieldsNormal] = useState([]);
  const [commsFields, setCommsFields] = useState([]);
  const [commsFieldsNormal, setCommsFieldsNormal] = useState([]);
  const [crPathMsg, setCrPathMsg] = useState(false);
  const [inBoundPaths, setInBoundPaths] = useState([]);
  const [ifbCommPaths, setIfbCommsPaths] = useState([]);
  const [plCommsPaths, setPlCommsPaths] = useState([]);
  const [phoCommsPaths, setPhoCommsPaths] = useState([]);
  const [commsPaths, setCommsPaths] = useState([]);
  const [invalidCrPath, setCrValidation] = useState(false);
  const [invalidGlobalPath, setGlobalValidation] = useState(false);
  const [invalidIfbPath, setIfbValidation] = useState(false);
  const [invalidPlPath, setPlValidation] = useState(false);
  const [invalidPhoPath, setPhoValidation] = useState(false);
  const [liveshotStatus, setLiveshotStatus] = useState("");
  const [sipPills, setSipPills] = useState({});
  const [summaryPath, setSummaryElement] = useState([]);
  const [isDeleteIconClicked, setIsDeleteIconClicked] = useState(false);
  const [confirmationDeleteMessage, setConfirmationDeleteMessage] =
    useState(null);
  const [isExtendActive, setIsExtendActive] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isHostEnabled, setIsHostEnabled] = useState(false);

  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }
  useEffect(() => {
    updateFormInfo();
  }, [formInfo]);

  useEffect(() => {
    setPreviousLSstatus(
      formInfo.readyForAir === true
        ? "READY FOR AIR"
        : formInfo.readyForPcr === true
        ? "READY FOR PCR"
        : formInfo.needsMediaTrafficReviewFlag === true
        ? "READY FOR MTC"
        : formInfo.canceled === true
        ? "CANCELED"
        : formInfo.clear === true
        ? "CLEAR"
        : "NOT READY"
    );
  }, []);

  useEffect(() => {
    if (isNewShotAdded) {
      onShowLiveShotInfo();
    }
  }, [isNewShotAdded]);

  useEffect(() => {
    if (availSignal) {
      if (availSignal.signal === undefined && noAvailVcModal !== null) {
        setNoAvailVcModal(true);
      }
    }
  }, [availSignal]);

  const triggerAvailVcInitState = () => {
    setNoAvailVcModal(false);
  };

  useEffect(() => {
    if (formInfo) {
      const userNameList = userList.find(
        (element) => element.sso === parseInt(formInfo.updatedBy)
      );
      if (userNameList !== undefined) {
        setUserName(userNameList.userName);
      }
      const userNameListabstract = userList.find(
        (element) =>
          element.sso === parseInt(formInfo.abstractLiveshot.updatedBy)
      );
      if (userNameListabstract !== undefined) {
        setAbstractUserName(userNameListabstract.userName);
      }
    }
  }, [formInfo, userList]);

  // Sets data after venue name is selected
  useEffect(() => {
    let studioNameArr = studioTruckList.filter((list) => {
      return list.stId === stId && list.softDelete === false;
    });
    if (
      isDisable &&
      !studioTruckData.hasOwnProperty("liveShotId") &&
      successMsg.studioData === undefined
    ) {
      setVenueName(studioNameArr.length > 0 ? studioNameArr[0].studioName : "");
      setVenuePhone(
        studioNameArr.length > 0 ? studioNameArr[0].studioPhone : ""
      );
      setVenueEmail(
        studioNameArr.length > 0 ? studioNameArr[0].studioEmail : ""
      );
      setVenueContact(
        studioNameArr.length > 0 ? studioNameArr[0].studioContact : ""
      );
      setVenueCost(
        studioNameArr.length > 0
          ? (studioNameArr[0].studioHourRate / 2).toFixed(2)
          : ""
      );
      setVenueNotes(
        studioNameArr.length > 0 ? studioNameArr[0].studioDesc : ""
      );
      setStudioLocation(
        studioNameArr.length > 0 ? studioNameArr[0].studioLocation : ""
      );
      setStudioIFB(
        studioNameArr.length > 0 ? studioNameArr[0].studio_default_ifb : ""
      );
      setStudioPL(
        studioNameArr.length > 0 ? studioNameArr[0].studio_default_pl : ""
      );
      setStudioPHO(
        studioNameArr.length > 0 ? studioNameArr[0].studio_default_pho : ""
      );
      setStudioDefaultTransmissionPath(
        studioNameArr.length > 0
          ? studioNameArr[0].studioDefaultTransmissionPath
          : ""
      );
      setStTypeId(studioNameArr.length > 0 ? studioNameArr[0].studioTypeId : 0);
    }

    if (
      (!isDisable &&
        studioTruckData !== "" &&
        formInfo !== undefined &&
        studioTruckData.liveShotId === formInfo.liveshotId) ||
      (!isDisable && newliveshot && studioTruckData !== "")
    ) {
      // When selecting Venue Name... All of these fields get auto populated
      setVenueName(studioTruckData.studioName);
      setVenuePhone(studioTruckData.studioPhone);
      setVenueEmail(studioTruckData.studioEmail);
      setVenueContact(studioTruckData.studioContact);

      setVenueCost((studioTruckData.studioHourRate / 2).toFixed(2));
      setStId(studioTruckData.stId);
      setVenueNotes(studioTruckData.studioDesc);
      setStudioLocation(studioTruckData.studioLocation);
      setVenueCity(studioTruckData.city);
      setVenueRegion(studioTruckData.region);
      setVenueCountry(studioTruckData.country);
      setVenueType(studioTruckData.studioTypeName);
      setLiveshotNotes(studioTruckData.liveshotNote || "");
      setVenueName(studioTruckData.studioName);

      setStudioDefaultTransmissionPath(studioTruckData.studioTx);

      setPoNo(studioTruckData.poNumber);

      setVenueSkype(studioTruckData.skype);
      setVenueQuicklink(studioTruckData.quickLink);
      setVenueWebOther(studioTruckData.webOther);

      setStudioIFB(
        studioTruckData.studio_default_ifb
          ? studioTruckData.studio_default_ifb
          : ""
      );
      setStudioPL(
        studioTruckData.studio_default_pl
          ? studioTruckData.studio_default_pl
          : ""
      );
      setStudioPHO(
        studioTruckData.studio_default_pho
          ? studioTruckData.studio_default_pho
          : ""
      );
      setStTypeId(
        studioTruckData.studioTypeId ? studioTruckData.studioTypeId : 0
      );
    }
    if (
      (successMsg.studioData &&
        formInfo !== undefined &&
        successMsg.studioData.liveShotId === formInfo.liveshotId) ||
      (!isDisable && newliveshot && successMsg.studioData)
    ) {
      setStId(successMsg.studioData.stId);
      setVenueName(successMsg.studioData.studioName);
      setVenuePhone(successMsg.studioData.studioPhone);
      setVenueContact(successMsg.studioData.studioContact);
      setVenueCost((successMsg.studioData.studioHourRate / 2).toFixed(2));
      setVenueNotes(successMsg.studioData.studioDesc);

      setStudioLocation(successMsg.studioData.studioLocation);
      setStTypeId(successMsg.studioData.studioTypeId);
      setStudioDefaultTransmissionPath(
        successMsg.studioData.studioDefaultTransmissionPath
      );
    }
  }, [
    studioTruckList,
    stId,
    isDisable,
    studioTruckData,
    id,
    token,
    successMsg.studioData,
    formInfo,
    newliveshot,
  ]);

  const [buttonstyle, setbuttonStyle] = useState("");
  const [buttonState, setButtonState] = useState("");
  useEffect(() => {
    setLiveshotStatus(buttonState);
  }, [buttonState]);
  useEffect(() => {
    const allStatus = [
      "NOT READY",
      "READY FOR MTC",
      "READY FOR PCR",
      "READY FOR AIR",
      "CLEAR",
      "CANCELED",
    ];
    if (allStatus[enterCount] === "READY FOR MTC") {
      setbuttonStyle("readyformediareview");
    } else if (allStatus[enterCount] === "READY FOR PCR") {
      setbuttonStyle("readyforcontrolroom");
    } else if (allStatus[enterCount] === "READY FOR AIR") {
      setbuttonStyle("readyforairstatus");
    } else if (allStatus[enterCount] === "CLEAR") {
      setbuttonStyle("clearstyle");
    } else {
      setbuttonStyle("notreadystyle");
    }
    setButtonState(allStatus[enterCount]);
  }, [enterCount]);

  useEffect(() => {
    setStype(stTypeId);
  }, [stTypeId]);

  const submitHandler = (e) => {
    updateAbstractFormInfo();
  };
  const afterSubmitHandler = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (updateLiveshotPerm || createSignalPerm || updateLiveshotStatusPerm) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [updateLiveshotPerm, createSignalPerm, updateLiveshotStatusPerm]);

  const options = [
    { value: "NOT READY", label: "NOT READY", className: "notReady-status" },
    { label: "READY FOR MTC", value: "READY FOR MTC", className: "mtc-status" },
    { label: "READY FOR PCR", value: "READY FOR PCR", className: "pcr-status" },
    { label: "CANCELED", value: "CANCELED", className: "cancel-status" },
    { label: "READY FOR AIR", value: "READY FOR AIR", className: "air-status" },
    { label: "CLEAR", value: "CLEAR", className: "clear-status" },
  ];

  useEffect(() => {
    if (clearLSWarningModal !== true) {
      if (newliveshot) {
        setButtonState(Constants.NOTREADY);
        setbuttonStyle("notreadystyle");
      }
      if (
        formInfo &&
        formInfo.needsMediaTrafficReviewFlag === false &&
        formInfo.readyForPcr === false &&
        formInfo.readyForAir === false &&
        formInfo.clear === false &&
        formInfo.canceled === false
      ) {
        setButtonState(Constants.NOTREADY);
        setbuttonStyle("notreadystyle");
      }
      if (
        formInfo &&
        formInfo.needsMediaTrafficReviewFlag === true &&
        formInfo.readyForAir === false &&
        formInfo.canceled === false
      ) {
        setButtonState(Constants.READY_FOR_MEDIA_TRAFFIC_REVIEW);
        setbuttonStyle("readyformediareview");
      }
      if (formInfo && formInfo.readyForPcr === true) {
        setButtonState(Constants.READY_FOR_CONTROL_ROOM);
        setbuttonStyle("readyforcontrolroom");
      }
      if (formInfo && formInfo.readyForAir === true) {
        setButtonState(Constants.READY_FOR_AIR);
        setbuttonStyle("readyforairstatus");
      }
      if (
        ((formInfo && formInfo.clear === true) || clearLSWarningYesOrNo) &&
        clearLSWarningModal === false
      ) {
        setButtonState(Constants.CLEAR);
        setbuttonStyle("clearstyle");
      }

      if (
        previousLSstatus &&
        clearLSWarningYesOrNo === false &&
        lsStatusPreviousClear === true
      ) {
        if (previousLSstatus === "NOT READY") {
          setButtonState(Constants.NOTREADY);
          setbuttonStyle("notreadystyle");
        } else if (previousLSstatus === "READY FOR MTC") {
          setButtonState(Constants.READY_FOR_MEDIA_TRAFFIC_REVIEW);
          setbuttonStyle("readyformediareview");
        } else if (previousLSstatus === "READY FOR PCR") {
          setButtonState(Constants.READY_FOR_CONTROL_ROOM);
          setbuttonStyle("readyforcontrolroom");
        } else if (previousLSstatus === "READY FOR AIR") {
          setButtonState(Constants.READY_FOR_AIR);
          setbuttonStyle("readyforairstatus");
        } else if (previousLSstatus === "CANCELED") {
          setButtonState(Constants.CANCELED);
          setbuttonStyle("canceledstyle");
        }

        const lsStatusList = options.map((option) => ({
          className: option.className,
          label: option.label,
          value: option.value,
        }));
        lsStatusList.forEach((ls) => {
          if (ls.value === previousLSstatus) {
            handleStatusChange(ls);
          }
        });
        setLsStatusPreviousClear(false);
      }

      if (formInfo && formInfo.canceled === true) {
        setButtonState(Constants.CANCELED);
        setbuttonStyle("canceledstyle");
      }
    }
  }, [formInfo, newliveshot, clearLSWarningYesOrNo, clearLSWarningModal]);

  useEffect(() => {
    if (currentCanvas && controlRoomList.length > 0) {
      let room = controlRoomList.find(
        (list) => list.roomId === currentCanvas.roomId
      );
      if (room) {
        setCurrentRoom(room);
        let coreInfo = controlRoomList.find(
          (list) => list.roomId === room.coreId
        );
        if (coreInfo) {
          setRoomCore(coreInfo);
        }
      }
    }
  }, [controlRoomList, currentCanvas, currentRoom]);

  const handleStatusChange = (e) => {
    let liveshotStatusValue = e.value;
    setLiveshotStatus(liveshotStatusValue);

    if (!updateLiveshotStatusPerm) return;
    if (e.value === Constants.NOTREADY) {
      setIsReadyPcr(false);
      setButtonState(Constants.NOTREADY);
      setPreviousLSstatus(Constants.NOTREADY);
      setbuttonStyle("notreadystyle");
    }
    if (e.value === Constants.READY_FOR_MEDIA_TRAFFIC_REVIEW) {
      setIsReadyPcr(false);
      setButtonState(Constants.READY_FOR_MEDIA_TRAFFIC_REVIEW);
      setPreviousLSstatus(Constants.READY_FOR_MEDIA_TRAFFIC_REVIEW);
      setbuttonStyle("readyformediareview");
    }
    if (e.value === Constants.READY_FOR_CONTROL_ROOM) {
      setIsReadyPcr(true);
      setButtonState(Constants.READY_FOR_CONTROL_ROOM);
      setPreviousLSstatus(Constants.READY_FOR_CONTROL_ROOM);
      setbuttonStyle("readyforcontrolroom");
    }
    if (e.value === Constants.READY_FOR_AIR) {
      if (isSubmitDisabled) {
        setIsInvalidSubmit(isSubmitDisabled);
        return;
      }
      setIsReadyPcr(false);
      setButtonState(Constants.READY_FOR_AIR);
      setPreviousLSstatus(Constants.READY_FOR_AIR);
      setbuttonStyle("readyforairstatus");
      e.value !== buttonState && submitHandler();
    }
    if (e.value === Constants.CLEAR && previousLSstatus != "CLEAR") {
      setClearLSWarningYesOrNo(true);
      triggerClearWarning();
    } else {
      setClearLSWarningYesOrNo(false);
    }

    if (e.value === Constants.CANCELED) {
      setIsReadyPcr(false);
      setButtonState(Constants.CANCELED);
      setPreviousLSstatus(Constants.CANCELED);
      setbuttonStyle("canceledstyle");
    }
    const formData = {};
    formData.clear =
      e.value === Constants.CLEAR && lsStatusPreviousClear === false
        ? true
        : false;
    formData.canceled = e.value === Constants.CANCELED ? true : false;
    formData.needsMediaTrafficReviewFlag =
      e.value === Constants.READY_FOR_MEDIA_TRAFFIC_REVIEW ? true : false;
    formData.readyForAir = e.value === Constants.READY_FOR_AIR ? true : false;
    formData.readyForPcr =
      e.value === Constants.READY_FOR_CONTROL_ROOM ? true : false;
    onUpdateStatus(currentCanvas, formInfo.liveshotId, formData, token);
  };

  const triggerClearWarning = () => {
    setClearLSWarningModal(true); // open modal
  };
  const confirmClearStatus = () => {
    setClearLSWarningYesOrNo(true);
    setClearLSWarningModal(false);
    setLsStatusPreviousClear(false);
    setPreviousLSstatus(Constants.CLEAR);
    //Need to call reset api endpoint
    resetSignals(token, formInfo, currentCanvasId);
  };

  const cancelClearStatus = () => {
    setClearLSWarningYesOrNo(false);
    setClearLSWarningModal(false);
    setLsStatusPreviousClear(true);
  };

  const closeAvailVcModal = () => {
    setNoAvailVcModal(false);
  };

  const recordToggle = (e) => {
    e.stopPropagation();
    const globalSet = !record;
    setRecord(globalSet);
    if (!isDisable) setRecord(!record);
    updateLiveshotData({
      globalSignal: globalSet,
      updatedBy: parseInt(userId),
    });
  };
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const onShowLiveShotInfo = (e) => {
    e && e.stopPropagation();
    e && e.preventDefault();
    setLiveShotInfoWrap(true);
    setLiveShotInfoLabel(false);
    if (updateLiveshotPerm || createSignalPerm || updateLiveshotStatusPerm) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  };

  const selectCityState = (cslocation) => {
    setLocation(cslocation);
    updateLiveshotData({ location: cslocation, updatedBy: parseInt(userId) });
  };

  const onUnShowLiveShotInfo = (event, flag) => {
    event.stopPropagation();
    event.preventDefault();
    isNewShotAdded && onResetAddedFromSearch();
    if (flag) {
      setLiveShotInfoWrap(false);
      setLiveShotInfoLabel(true);
    } else {
      setLiveShotInfoWrap(true);
      setLiveShotInfoLabel(false);
      if (updateLiveshotPerm || createSignalPerm || updateLiveshotStatusPerm) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    }
  };

  const checkStartEndTimeValue = (updateValue) => {
    if (
      updateValue.windowStartTime &&
      !updateValue.windowEndTime &&
      formInfo.windowEndTime !== windowEndTime
    ) {
      let endDate = window.chrono.parseDate(windowEndTime);
      updateValue.windowEndTime = endDate
        ? moment(endDate, ["HH.mm"]).format("hh:mm A")
        : "";
    } else if (
      updateValue.windowEndTime &&
      !updateValue.windowStartTime &&
      formInfo.windowStartTime !== windowStartTime
    ) {
      let startDate = window.chrono.parseDate(windowStartTime);
      updateValue.windowStartTime = startDate
        ? moment(startDate, ["HH.mm"]).format("hh:mm A")
        : "";
    }
  };

  const updateLiveshotData = (updateValue = {}, studioData = {}) => {
    if (formInfo) {
      checkStartEndTimeValue(updateValue);
      Object.assign(formInfo, updateValue);
      Object.assign(
        studioTruckData,
        Object.keys(studioData).length > 0 ? studioData : updateValue
      );
      onUpdateLiveShotOnChange(formInfo.liveshotId, updateValue, token);
    }
  };

  const onWindowStartTimeChange = (e) => {
    setValidStartTime(false);
    let result = null;
    if (e.target.value !== "" || e.target.value !== null) {
      let date = window.chrono.parseDate(e.target.value);
      if (
        date === null &&
        e.target.value !== "" &&
        !e.target.value.includes(":")
      ) {
        date = e.target.value.slice(0, 2) + ":" + e.target.value.slice(2);
        date = window.chrono.parseDate(date);
      }
      result = date ? moment(date, ["HH.mm"]).format("hh:mm A") : "";
      setWindowStartTime("");
      setWindowStartTime(result || e.target.value || "");
    } else {
      setWindowStartTime("");
    }
    if (result === "" && e.target.value !== "") {
      setValidStartTime(true);
    } else if (
      result != "" &&
      windowEndTime &&
      window.chrono.parseDate(result) >= window.chrono.parseDate(windowEndTime)
    ) {
      setValidStartTime(true);
      if (
        result.toLowerCase().includes("pm") &&
        windowEndTime.toLowerCase().includes("am")
      ) {
        setValidStartTime(false);
        updateLiveshotData({
          windowStartTime: result ? result : "",
          updatedBy: parseInt(userId),
        });
      }
    } else {
      updateLiveshotData({
        windowStartTime: result ? result : "",
        updatedBy: parseInt(userId),
      });
    }
    // UI red border check if End time has changed
    if (
      window.chrono.parseDate(result) < window.chrono.parseDate(windowEndTime)
    ) {
      setValidEndTime(false);
    }
  };

  const onWindowEndTimeChange = (e) => {
    setValidEndTime(false);
    let result = null;
    if (e.target.value !== "" || e.target.value !== null) {
      let date = window.chrono.parseDate(e.target.value);
      if (
        date === null &&
        e.target.value !== "" &&
        !e.target.value.includes(":")
      ) {
        date = e.target.value.slice(0, 2) + ":" + e.target.value.slice(2);
        date = window.chrono.parseDate(date);
      }
      result = date ? moment(date, ["HH.mm"]).format("hh:mm A") : "";
      setWindowEndTime("");
      setWindowEndTime(result || e.target.value || "");
    } else {
      setWindowEndTime("");
    }

    if (result === "" && e.target.value !== "") {
      setValidEndTime(true);
    } else if (
      result != "" &&
      windowStartTime &&
      window.chrono.parseDate(result) <=
        window.chrono.parseDate(windowStartTime)
    ) {
      setValidEndTime(true);
      if (
        result.toLowerCase().includes("am") &&
        windowStartTime.toLowerCase().includes("pm")
      ) {
        setValidEndTime(false);
        setValidStartTime(false);
        updateLiveshotData({
          windowEndTime: result ? result : "",
          updatedBy: parseInt(userId),
        });
      }
    } else {
      updateLiveshotData({
        windowEndTime: result ? result : "",
        updatedBy: parseInt(userId),
      });
    }
    // UI red border check if Start time has changed
    if (
      window.chrono.parseDate(result) > window.chrono.parseDate(windowStartTime)
    ) {
      setValidStartTime(false);
    }
  };

  const onHitTimeChange = (e) => {
    setValidHitTime(false);
    let result = null;
    let startTimeParsed = null;
    let endTimeParsed = null;
    if (e.target.value !== "" || e.target.value !== null) {
      let date = window.chrono.parseDate(e.target.value);

      if (
        date === null &&
        e.target.value !== "" &&
        !e.target.value.includes(":")
      ) {
        date = e.target.value.slice(0, 2) + ":" + e.target.value.slice(2);
        date = window.chrono.parseDate(date);
      }
      result = date ? moment(date, ["HH.mm"]).format("hh:mm A") : "";
      // Check if start or endtime exist
      if (
        (windowStartTime === "" || windowStartTime === null) &&
        (windowEndTime === "" || windowEndTime === null) &&
        result.length > 0
      ) {
        //Assign Start/End Times if these don't exist or aren't valid
        let resultStart = moment(date).subtract(30, "minutes").toDate();
        let resultEnd = moment(date).add(30, "minutes").toDate();

        startTimeParsed = resultStart
          ? moment(resultStart, ["HH.mm"]).format("hh:mm A")
          : "";
        endTimeParsed = resultEnd
          ? moment(resultEnd, ["HH.mm"]).format("hh:mm A")
          : "";
        setWindowStartTime(startTimeParsed);
        setWindowEndTime(endTimeParsed);

        setValidEndTime(false);
        setValidStartTime(false);
        updateLiveshotData({
          windowStartTime: startTimeParsed,
          windowEndTime: endTimeParsed,
          hitTime: result,
          updatedBy: parseInt(userId),
        });
      }
    }
    setHitTime("");
    setHitTime(result || e.target.value || "");

    if (!result && e.target.value !== "") {
      setValidHitTime(true);
    } else if (
      (startTimeParsed === null && endTimeParsed === null) ||
      e.target.value === ""
    ) {
      updateLiveshotData({
        hitTime: result,
        updatedBy: parseInt(userId),
      });
    }
  };

  // Liveshot Name Onchange
  const onChangeLiveShotName = (e) => {
    let targetValue = e.target.value.toUpperCase();
    if (e.target.value !== "") {
      setLiveshotName(targetValue);
      setShowSuggestion(true);
      updateLiveshotData({
        liveshotName: targetValue,
        updatedBy: parseInt(userId),
      });
    } else {
      setLiveshotName("");
    }
  };

  // Venue Skype Onchange
  const onChangeSkype = (e) => {
    let targetValue = e.target.value.toUpperCase();
    setVenueSkype(targetValue);
    updateLiveshotData({ skype: targetValue, updatedBy: parseInt(userId) });
  };

  // Venue Quicklink Onchange
  const onChangeQuicklink = (e) => {
    let targetValue = e.target.value.toUpperCase();
    setVenueQuicklink(targetValue);
    updateLiveshotData({ quickLink: targetValue, updatedBy: parseInt(userId) });
  };

  // Venue Web Other Onchange
  const onChangeWebOther = (e) => {
    let targetValue = e.target.value.toUpperCase();
    setVenueWebOther(targetValue);
    updateLiveshotData({ webOther: targetValue, updatedBy: parseInt(userId) });
  };
  // Venue City Onchange
  const onChangeCity = (e) => {
    let targetValue = e.target.value.toUpperCase();
    setVenueCity(targetValue);
    updateLiveshotData({ city: targetValue, updatedBy: parseInt(userId) });
  };

  // Venue Region Onchange
  const onChangeRegion = (e) => {
    let targetValue = e.target.value.toUpperCase();
    setVenueRegion(targetValue);
    updateLiveshotData({ region: targetValue, updatedBy: parseInt(userId) });
  };

  // Venue Country Onchange

  const onChangeCountry = (e) => {
    let targetValue = e.target.value.toUpperCase();
    setVenueCountry(targetValue);
    updateLiveshotData({ country: targetValue, updatedBy: parseInt(userId) });
  };

  //Venue Tx Onchange
  const onChangeTxpath = (e) => {
    let targetValue = e.target.value.toUpperCase();
    setStudioDefaultTransmissionPath(targetValue);
    updateLiveshotData(
      {
        tx: targetValue,
        updatedBy: parseInt(userId),
      },
      { studioTx: targetValue }
    );
  };

  // Venue Contact Name Onchange
  const onChangeVenueContactName = (e) => {
    let targetValue = e.target.value.toUpperCase();
    setVenueContact(targetValue);
    updateLiveshotData(
      {
        liveshotContactName: targetValue,
        updatedBy: parseInt(userId),
      },
      { studioContact: targetValue }
    );
  };

  const onChangeContactDetails = (updateValue, studioData) => {
    setVenueContact(updateValue.liveshotContactName);
    setVenuePhone(updateValue.liveshotContactPhone);
    setVenueEmail(updateValue.liveshotContactEmail);
    Object.assign(formInfo, updateValue);
    Object.assign(studioTruckData, studioData);
  };

  // Venue Phone Onchange
  const onChangeVenueContactPhone = (e) => {
    let phoneNo = e.target.value;

    setVenuePhone(phoneNo);
    updateLiveshotData(
      {
        liveshotContactPhone: phoneNo,
        updatedBy: parseInt(userId),
      },
      { studioPhone: phoneNo }
    );
    // }
  };
  // Venue Email Onchange
  const onChangeVenueContactEmail = (e) => {
    let email = e.target.value;
    setVenueEmail(email);
    updateLiveshotData(
      {
        liveshotContactEmail: email,
        updatedBy: parseInt(userId),
      },
      { studioEmail: email }
    );
  };
  const handlePinCommsSync = (e) => {
    // Validation for pincomms button
    if (!venueName || !liveshotName || !hitTime) {
      showNotificationBar(
        liveshot_canvas_constants.PIN_COMMS_ERROR_MESSAGE,
        true
      );
      return false;
    } else {
      return true;
    }
  };
  // PO Number Onchange
  const onChangePoNumber = (e) => {
    let targetValue = e.target.value;
    setPoNo(targetValue);
    updateLiveshotData({ poNumber: targetValue, updatedBy: parseInt(userId) });
  };

  const onCloseSuggestion = () => {
    setShowSuggestion(false);
  };

  const onChangeVenueType = (e) => {
    var a = document.querySelectorAll("li.ul-list-item");
    for (var i = 0; i < a.length; i++) {
      a[i].classList.remove("list-item-bg");
    }
    e.target.classList.add("list-item-bg");
    if (e.target.innerHTML === "VIDEO CONFERENCE") {
      updateLiveshotData({
        venueType: e.target.innerHTML,
        city: null,
        region: null,
        country: null,
        updatedBy: parseInt(userId),
      });
      setVenueCity(null);
      setVenueRegion(null);
      setVenueCountry(null);
    } else if (
      e.target.innerHTML === "AD HOC FEED" ||
      e.target.innerHTML === "TRUCK"
    ) {
      updateLiveshotData({
        venueType: e.target.innerHTML,
        skype: null,
        quickLink: null,
        webOther: null,
        city: null,
        region: null,
        country: null,
        updatedBy: parseInt(userId),
      });
      setVenueCity(null);
      setVenueRegion(null);
      setVenueCountry(null);
      setVenueSkype(null);
      setVenueQuicklink(null);
      setVenueWebOther(null);
    } else {
      updateLiveshotData({
        venueType: e.target.innerHTML,
        skype: null,
        quickLink: null,
        webOther: null,
        updatedBy: parseInt(userId),
      });
      setVenueSkype(null);
      setVenueQuicklink(null);
      setVenueWebOther(null);
    }
    setVenueType(e.target.innerHTML);
    setShowVenue(false);
    setTempVType("");
  };

  const hideVenueTypes = () => {
    setShowVenue((current) => !current);

    if (setShowVenue !== false) {
      setTempVType("VENUE TYPE");
    }
    if (venueType !== "" && setShowVenue !== true) {
      setTempVType("");
    }
  };

  const onStudioVenueNameChange = (e, stId) => {
    let targetValue = e.target.value;
    setVenueName(targetValue);

    if (targetValue.length > 2) {
      const formData = {};
      formData.venue = targetValue.toUpperCase();
      formData.updatedBy = parseInt(userId);
      formData.stId = stId;
      updateLiveshotData(formData);
    }
  };

  const updateStatus = useCallback(
    (index) => {
      const formData = {
        needsMediaTrafficReviewFlag: false,
        readyForAir: false,
        readyForPcr: false,
        canceled: false,
        clear: false,
      };
      const allStatus = [
        "NOT READY",
        "READY FOR MTC",
        "READY FOR PCR",
        "READY FOR AIR",
        "CLEAR",
        "CANCELED",
      ];
      if (!clearLSWarningYesOrNo) {
        if (allStatus[index] === "READY FOR MTC") {
          formData.needsMediaTrafficReviewFlag = true;
        } else if (allStatus[index] === "READY FOR PCR") {
          formData.readyForPcr = true;
        } else if (allStatus[index] === "READY FOR AIR") {
          formData.readyForAir = true;
        } else if (allStatus[index] === "CLEAR") {
          formData.clear = true;
        } else if (allStatus[index] === "CANCELED") {
          formData.canceled = true;
        }
      }
    },
    [currentCanvas, formInfo.liveshotId, token, onUpdateStatus]
  );

  const handleUseButton = () => {
    onTakeAll(
      token,
      userId,
      userFullName,
      inBoundFields,
      inBoundFieldsNormal,
      formInfo.liveshotId,
      currentCanvas.roomId,
      currentRoom,
      crPathMsg,
      liveShotSignalSelected
    );
  };
  const handleOwnButton = () => {
    let signalsArr = [...outBoundFields, ...commsFields];
    onGrabAll(
      token,
      userId,
      userFullName,
      outBoundFieldsNormal,
      commsFieldsNormal,
      signalsArr,
      currentCanvas.roomId,
      currentRoom,
      crPathMsg,
      liveShotSignalSelected,
      currentCanvas
    );
  };
  useEffect(() => {
    let signalsArr = [...outBoundFields, ...commsFields];
    if (activeSlideRef.current) {
      activeSlideRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
    if (
      (pressedKey === "u" || pressedKey === "U") &&
      activeLiveshot === formInfo.liveshotId &&
      isArmRouting &&
      isReadyPcr
    ) {
      handleUseButton();
    }
    if (pressedKey === "u_next" && nextActiveLiveshot === formInfo.liveshotId) {
      handleUseButton();
    }
    if (
      (pressedKey === "o" || pressedKey === "O") &&
      activeLiveshot === formInfo.liveshotId &&
      isArmRouting &&
      isReadyPcr
    ) {
      onGrabAll(
        token,
        userId,
        userFullName,
        outBoundFieldsNormal,
        commsFieldsNormal,
        signalsArr,
        currentCanvas.roomId,
        currentRoom,
        crPathMsg,
        liveShotSignalSelected
      );
    }
    if (pressedKey === "o_next" && nextActiveLiveshot === formInfo.liveshotId) {
      onGrabAll(
        token,
        userId,
        userFullName,
        outBoundFieldsNormal,
        commsFieldsNormal,
        signalsArr,
        currentCanvas.roomId,
        currentRoom,
        crPathMsg,
        liveShotSignalSelected
      );
    }
  }, [
    isArmRouting,
    isReadyPcr,
    activeLiveshot,
    nextActiveLiveshot,
    pressedKey,
    formInfo.liveshotId,
    onTakeAll,
    token,
    userId,
    userFullName,
    inBoundFields,
    inBoundFieldsNormal,
    currentCanvas.roomId,
    currentRoom,
    crPathMsg,
    onGrabAll,
    outBoundFields,
    outBoundFieldsNormal,
    commsFields,
    commsFieldsNormal,
  ]);

  useEffect(() => {
    const btn = document.getElementsByClassName("edit-btn");
    const active = document.getElementsByClassName("highlight");
    if (
      (pressedKey === "e" || pressedKey === "E") &&
      activeLiveshot === formInfo.liveshotId
    ) {
      if (btn.length > 0) {
        document.getElementsByClassName("edit-btn")[0].click();
      }
    }
    if (
      (pressedKey === "f" || pressedKey === "F") &&
      activeLiveshot === formInfo.liveshotId
    ) {
      active[0].querySelector(".foldunfoldhide").click();
    }
    if (
      (pressedKey === "Enter" || pressedKey === "Backspace") &&
      activeLiveshot === formInfo.liveshotId
    ) {
      const val = active[0].querySelector(".btnstatus").value;
      const allStatus = [
        "NOT READY",
        "READY FOR MTC",
        "READY FOR PCR",
        "READY FOR AIR",
        "CLEAR",
        "CANCELED",
      ];
      const statusIndex = allStatus.findIndex((item) => item === val);
      if (pressedKey === "Enter") {
        if (statusIndex < 5) {
          const nextIndex = statusIndex + 1;
          setEnterCount(nextIndex);
          updateStatus(nextIndex);
        }
      }
      if (pressedKey === "Backspace") {
        if (statusIndex > 0) {
          const prevIndex = statusIndex - 1;
          setEnterCount(prevIndex);
          updateStatus(prevIndex);
        }
      }
    }
  }, [pressedKey, activeLiveshot, formInfo.liveshotId, updateStatus]);

  useEffect(() => {
    if (formInfo) {
      const inboundArray = [];
      const outboundArray = [];
      const commsArray = [];
      const sipArray = new Set([]);
      const inbound = removeDuplicates(liveShotSignalSelected).filter(
        (signal) =>
          signal.abstractLiveshotId ===
            formInfo.abstractLiveshot.abstractLiveshotId &&
          formInfo.liveshotId === signal.liveshotId &&
          signal.liveshotSignalTypeId === 1
      );
      for (let key in inbound) {
        inboundArray.push(inbound[key].liveshotSignalId);
      }
      setInBoundFields(inbound);
      setInBoundFieldsNormal(
        removeDuplicates(liveShotSignalSelected).filter(
          (signal) =>
            signal.liveshotId === formInfo.liveshotId &&
            signal.abstractLiveshotId === 0 &&
            inboundArray.includes(signal.abstractLiveshotSignalId)
        )
      );

      const comms = removeDuplicates(liveShotSignalSelected).filter(
        (signal) =>
          signal.abstractLiveshotId ===
            formInfo.abstractLiveshot.abstractLiveshotId &&
          formInfo.liveshotId === signal.liveshotId &&
          (signal.liveshotSignalTypeId === 5 ||
            signal.liveshotSignalTypeId === 7 ||
            signal.liveshotSignalTypeId === 8 ||
            signal.liveshotSignalTypeId === 9)
      );
      setCommsFields(comms);
      for (let key in comms) {
        commsArray.push(comms[key].liveshotSignalId);
      }
      setCommsFieldsNormal(
        removeDuplicates(liveShotSignalSelected).filter(
          (signal) =>
            signal.liveshotId === formInfo.liveshotId &&
            signal.abstractLiveshotId === 0 &&
            commsArray.includes(signal.abstractLiveshotSignalId)
        )
      );

      const outbound = removeDuplicates(liveShotSignalSelected).filter(
        (signal) =>
          signal.abstractLiveshotId ===
            formInfo.abstractLiveshot.abstractLiveshotId &&
          formInfo.liveshotId === signal.liveshotId &&
          signal.liveshotSignalTypeId === 2
      );
      setOutBoundFields(outbound);
      for (let key in outbound) {
        outboundArray.push(outbound[key].liveshotSignalId);
      }
      setOutBoundFieldsNormal(
        removeDuplicates(liveShotSignalSelected).filter(
          (signal) =>
            signal.liveshotId === formInfo.liveshotId &&
            signal.abstractLiveshotId === 0 &&
            outboundArray.includes(signal.abstractLiveshotSignalId)
        )
      );
    }
  }, [liveShotSignalSelected, formInfo]);

  useEffect(() => {
    let ifbFlag = true;
    let plFlag = true;
    let phoFlag = true;
    let crValidity = [];
    let sendReceiveValidity = [];
    let commValidity = [];
    let globalTakeCheck = [];
    let crTakeCheck = [];
    let globalGrabCheck = [];
    let crGrabCheck = [];
    const inUseCheck = [];
    let paths = [];
    let hasInValidCrPath = false;
    let hasInValidGlobalPath = false;
    let hasInvalidIfbPath = false;
    let hasInvalidPlPath = false;
    let hasInvalidPhoPath = false;
    let hasInvalidSipPath = false;
    let ifbPaths = [];
    let plPaths = [];
    let phoPaths = [];
    let ifbSips = [];
    let phoSip = "";
    let plSip = "";
    let summary_path = [];
    inBoundFields.forEach((fields, index) => {
      let globalPathNameInput = null;
      let crPathNameInput = null;
      let styling = "form-control send_receive";
      let globalPathName = trimCrAndPcFromPathName(fields.globalPathName);
      if (
        (fields.tx || fields.globalPathName || fields.crPathName) &&
        summary_path.length < 3
      ) {
        summary_path.push(fields);
      }
      let crPathNameNormal = inBoundFieldsNormal.find(
        (inboundNormal) =>
          inboundNormal.abstractLiveshotSignalId === fields.liveshotSignalId
      );
      if (crPathNameNormal && crPathNameNormal.crPathName) {
        let isCrPath = inBoundCRPath.find(
          (item) => item === crPathNameNormal.crPathName
        );

        if (!isCrPath) {
          sendReceiveValidity.push(true);
          crValidity.push(true);
        }
      }
      if (hasValidPath(fields, "crPathName", inBoundCRPath)) {
        hasInValidCrPath = true;
      }
      if (hasValidPath(fields, "globalPathName", inBoundGlobalPath)) {
        hasInValidGlobalPath = true;
      }
      let isGlobalPath = inBoundGlobalPath.find(
        (item) => item === fields.globalPathName
      );
      if (!isGlobalPath && fields.uniLateral) {
        sendReceiveValidity.push(true);
      } else if (isGlobalPath && fields.uniLateral) {
        if (
          !formInfo.windowEndTime &&
          sharedInBoundGlobalPath.includes(isGlobalPath)
        ) {
          sendReceiveValidity.push(true);
        }
      }
      if (!fields.uniLateral && crPathNameNormal) {
        let isGlobal = inBoundExtendGlobalPath.find(
          (item) => item === crPathNameNormal.globalPathName
        );
        if (!isGlobal) {
          sendReceiveValidity.push(true);
        } else {
          if (
            !formInfo.windowEndTime &&
            sharedInBoundGlobalPath.includes(isGlobal)
          ) {
            sendReceiveValidity.push(true);
          }
        }
      }
      let crPathName = trimCrFromPathName(
        crPathNameNormal ? crPathNameNormal.crPathName : null
      );
      if (crPathName !== fields.crPathName) styling += " send_receive-xl";

      if (fields.globalPathName && fields.globalPathName !== "") {
        globalPathNameInput = (
          <input
            key={"inbound-global-" + index}
            type="text"
            className={styling}
            placeholder="-- --"
            name="send_receive-global"
            value={globalPathName || ""}
            disabled={true}
          />
        );
      } else {
        globalTakeCheck.push(true);
        if (fields.uniLateral) {
          sendReceiveValidity.push(true);
        }
      }
      if (crPathNameNormal && crPathNameNormal.crPathName !== "") {
        crPathNameInput = (
          <input
            key={"inbound-cr-" + index}
            type="text"
            className={styling}
            placeholder="-- --"
            name="send_receive-cr"
            value={crPathName}
            disabled={true}
          />
        );
      } else {
        crTakeCheck.push(true);
        sendReceiveValidity.push(true);
        crValidity.push(true);
      }

      if (
        crPathNameNormal &&
        (crPathNameNormal.globalPathName === "" ||
          crPathNameNormal.globalPathName === null) &&
        fields.uniLateral === false
      ) {
        sendReceiveValidity.push(true);
      }

      let isRedBorder = redBorder.find(
        (item) => item.liveshotSignalId === fields.liveshotSignalId
      );
      if (isRedBorder) {
        sendReceiveValidity.push(true);
      }

      paths.push([globalPathNameInput, crPathNameInput]);
    });

    setInBoundPaths(paths);

    outBoundFields.forEach((fields, index) => {
      let crPathNameNormal = outBoundFieldsNormal.find(
        (outboundNormal) =>
          outboundNormal.abstractLiveshotSignalId === fields.liveshotSignalId
      );
      if (
        (fields.tx || fields.globalPathName || fields.crPathName) &&
        summary_path.length < 3
      ) {
        summary_path.push(fields);
      }
      if (hasValidPath(fields, "crPathName", outBoundCRPath)) {
        hasInValidCrPath = true;
      }
      if (hasValidPath(fields, "globalPathName", outBoundGlobalPath)) {
        hasInValidGlobalPath = true;
      }
      if (crPathNameNormal && crPathNameNormal.crPathName) {
        let isCrPath = outBoundCRPath.find(
          (item) => item === crPathNameNormal.crPathName
        );
        if (!isCrPath) {
          sendReceiveValidity.push(true);
          crValidity.push(true);
        }
      }

      let isGlobalPath = outBoundGlobalPath.find(
        (item) => item === fields.globalPathName
      );
      if (!isGlobalPath) {
        sendReceiveValidity.push(true);
      } else {
        if (
          sharedOutBoundGlobalPath.includes(isGlobalPath) &&
          !formInfo.windowEndTime
        ) {
          sendReceiveValidity.push(true);
        }
      }

      if (fields.globalPathName === "" && fields.uniLateral) {
        globalGrabCheck.push(true);
        sendReceiveValidity.push(true);
      } else if (fields.globalPathName === "" && !fields.uniLateral) {
        sendReceiveValidity.pop();
      }
      if (crPathNameNormal && crPathNameNormal.crPathName === "") {
        crGrabCheck.push(true);
        sendReceiveValidity.push(true);
        crValidity.push(true);
      }

      if (
        crPathNameNormal &&
        (crPathNameNormal.globalPathName === "" ||
          crPathNameNormal.globalPathName === null) &&
        fields.uniLateral === false
      ) {
        sendReceiveValidity.push(true);
      }

      let isRedBorder = redBorder.find(
        (item) => item.liveshotSignalId === fields.liveshotSignalId
      );
      if (isRedBorder) {
        sendReceiveValidity.push(true);
      }
      const grabbed = grabbedSignals.filter(
        (item) =>
          item.liveshotSignalId === fields.liveshotSignalId &&
          parseInt(item.grabbedBy) !== parseInt(userId)
      );
      if (grabbed.length > 0) {
        inUseCheck.push(true);
      }
    });
    commsFields.forEach((fields, index) => {
      const commCRArray = [
        ...ifbCRPath,
        ...plCRPath,
        ...phoCRPath,
        ...phoTxCRPath,
      ];
      let sip = fields.sip || "";
      let pin = fields.pin || "";
      const crCommsList = fields.transmissionPathName.includes("IFB")
        ? ifbCRPath
        : fields.transmissionPathName.includes("PL")
        ? plCRPath
        : fields.transmissionPathName.includes("PHO")
        ? phoCRPath
        : [];
      let crPathNameSegments = splitSignalAndPhone(
        fields.crPathName,
        fields.transmissionPathName,
        crCommsList
      );
      if (fields.transmissionPathName !== "") {
        if (
          (sip.length === 4 || pin.length === 4 || fields.pathName) &&
          fields.transmissionPathName.includes("IFB")
        ) {
          ifbSips.push(fields);
        }
        if (fields.transmissionPathName.includes("IFB")) {
          crPathNameSegments[0] && ifbPaths.push(crPathNameSegments[0]);
          if (hasValidPath(fields, "crPathName", ifbCRPath)) {
            hasInvalidIfbPath = true;
          }
        }
        if (
          plSip === "" &&
          (sip.length === 4 || pin.length === 4 || fields.pathName) &&
          fields.transmissionPathName.includes("PL")
        ) {
          plSip = fields;
        }
        if (fields.transmissionPathName.includes("PL")) {
          crPathNameSegments[0] && plPaths.push(crPathNameSegments[0]);
          if (hasValidPath(fields, "crPathName", plCRPath)) {
            hasInvalidPlPath = true;
          }
        }
        if (
          phoSip === "" &&
          (sip.length === 4 || pin.length === 4 || fields.pathName) &&
          fields.transmissionPathName.includes("PHO")
        ) {
          phoSip = fields;
        }
        if (fields.transmissionPathName.includes("PHO")) {
          crPathNameSegments[0] && phoPaths.push(crPathNameSegments[0]);
          if (hasValidPath(fields, "crPathName", phoCRPath)) {
            hasInvalidPhoPath = true;
          }
        }
      }
      if (fields && fields.crPathName) {
        setFirstIfbGlobalPath(fields.globalPathName);
      }
      if (
        fields &&
        fields.liveshotSignalTypeId === 5 &&
        ifbFlag &&
        fields.globalPathName !== ""
      ) {
        ifbFlag = false;
        setFirstIfbGlobalPath(fields.globalPathName);
      }
      if (
        fields &&
        fields.liveshotSignalTypeId === 8 &&
        plFlag &&
        fields.globalPathName !== ""
      ) {
        plFlag = false;
        setFirstPlGlobalPath(fields.globalPathName);
      }
      if (
        fields &&
        fields.liveshotSignalTypeId === 9 &&
        phoFlag &&
        fields.globalPathName !== ""
      ) {
        phoFlag = false;
        setFirstPhoGlobalPath(fields.globalPathName);
      }
      let crPathNameNormal = commsFieldsNormal.find(
        (commsNormal) =>
          commsNormal.abstractLiveshotSignalId === fields.liveshotSignalId
      );
      if (fields.globalPathName === "" || fields.globalPathName === null) {
        globalGrabCheck.push(true);
      }

      if (crPathNameNormal && crPathNameNormal.crPathName) {
        let isCrPath = true;

        isCrPath = commCRArray.find(
          (item) => item === crPathNameNormal.crPathName
        );
        if (!isCrPath) {
          commValidity.push(true);
          crValidity.push(true);
        }
      }

      if (crPathNameNormal && crPathNameNormal.crPathName === "") {
        commValidity.push(true);
        crGrabCheck.push(true);
        crValidity.push(true);
      }

      let commsIfbGlobalPathInfo = ifbGlobalPath.find(
        (item) => item === fields.globalPathName
      );
      let commsPlGlobalPathInfo = plGlobalPath.find(
        (item) => item === fields.globalPathName
      );
      let commsPhoGlobalPathInfo = phoGlobalPath.find(
        (item) => item === fields.globalPathName
      );
      let commsPhoTxGlobalPathInfo = phoTxGlobalPath.find(
        (item) => item === fields.globalPathName
      );

      let isRedBorder = redBorder.find(
        (item) => item.liveshotSignalId === fields.liveshotSignalId
      );
      if (isRedBorder) {
        commValidity.push(true);
      }
      const grabbed = grabbedSignals.filter(
        (item) =>
          item.liveshotSignalId === fields.liveshotSignalId &&
          parseInt(item.grabbedBy) !== parseInt(userId)
      );
      if (grabbed.length > 0) {
        inUseCheck.push(true);
      }
    });

    if (crValidity.length > 0) {
      setCrPathMsg(true);
    } else {
      setCrPathMsg(false);
    }
    setCrValidation(hasInValidCrPath);
    setGlobalValidation(hasInValidGlobalPath);
    setIfbCommsPaths(ifbPaths);
    setPlCommsPaths(plPaths);
    setPhoCommsPaths(phoPaths);
    setIfbValidation(hasInvalidIfbPath);
    setPlValidation(hasInvalidPlPath);
    setPhoValidation(hasInvalidPhoPath);
    setSummaryElement(summary_path);
    setSipPills({
      ifbSip1: ifbSips["0"] ? ifbSips["0"] : "",
      ifbSip2: ifbSips["1"] ? ifbSips["1"] : "",
      plSip,
      phoSip,
    });
  }, [
    formInfo.windowEndTime,
    inBoundFields,
    inBoundFieldsNormal,
    outBoundFields,
    commsFields,
    commsFieldsNormal,
    grabbedSignals,
    userId,
    inBoundCRPath,
    inBoundGlobalPath,
    outBoundCRPath,
    outBoundGlobalPath,
    outBoundFieldsNormal,
    inBoundExtendGlobalPath,
    redBorder,
    ifbCRPath,
    ifbGlobalPath,
    plGlobalPath,
    phoGlobalPath,
    phoTxGlobalPath,
    phoTxCRPath,
    plCRPath,
    phoCRPath,
    sharedInBoundGlobalPath,
    sharedOutBoundGlobalPath,
    sharedIFBGlobalPath,
    sharedPLGlobalPath,
    sharedPHOTXGlobalPath,
    formattedRouterSource,
  ]);

  useEffect(() => {
    let paths = [];

    let ifbPaths = [];
    let plPaths = [];
    let phoPaths = [];
    commsFieldsNormal.forEach((fields, index) => {
      let crStyling = "comms";
      let crPathNameInput0 = null;
      let crPathNameInput1 = null;
      let crPhoneStyling = crStyling + " comms-phone";
      let crPathNameSegments = splitSignalAndPhone(
        fields.crPathName,
        fields.transmissionPathName
      );
      if (fields.crPathName !== "" && fields.crPathName !== null) {
        if (fields.crPathName.includes("IFB")) {
          crPathNameInput0 = (
            <span key={"cr0" + index} className={crStyling}>
              {crPathNameSegments[0]}
            </span>
          );
          crPathNameInput1 = (
            <span key={"cr1" + index} className={crPhoneStyling}>
              {crPathNameSegments[1]}
            </span>
          );
          ifbPaths.push([crPathNameInput0, crPathNameInput1]);
          setIfbCommsPaths(ifbPaths);
        }
        if (fields.crPathName.includes("PL")) {
          crPathNameInput0 = (
            <span key={"cr0" + index} className={crStyling}>
              {crPathNameSegments[0]}
            </span>
          );
          crPathNameInput1 = (
            <span key={"cr1" + index} className={crPhoneStyling}>
              {crPathNameSegments[1]}
            </span>
          );
          plPaths.push([crPathNameInput0, crPathNameInput1]);
          setPlCommsPaths(plPaths);
        }
        if (fields.crPathName.includes("PH0")) {
          crPathNameInput0 = (
            <span key={"cr0" + index} className={crStyling}>
              {crPathNameSegments[0]}
            </span>
          );
          crPathNameInput1 = (
            <span key={"cr1" + index} className={crPhoneStyling}>
              {crPathNameSegments[1]}
            </span>
          );
          phoPaths.push([crPathNameInput0, crPathNameInput1]);
          setPhoCommsPaths(phoPaths);
        }
        if (fields.transmissionPathName !== "PHO") {
          crPathNameInput0 = (
            <span key={"cr0" + index} className={crStyling}>
              {crPathNameSegments[0]}
            </span>
          );
          crPathNameInput1 = (
            <span key={"cr1" + index} className={crPhoneStyling}>
              {crPathNameSegments[1]}
            </span>
          );
          paths.push([crPathNameInput0, crPathNameInput1]);
        }
      }
    });
    setCommsPaths(paths);
  }, [commsFields, commsFieldsNormal]);

  useEffect(() => {
    const mandatoryFields = !liveshotName || !venueName || !venueType;
    let hasContactRequiredFields =
      !VENUE_TYPES.includes(venueType) && (!venueCity || !venueRegion);
    if (mandatoryFields || hasContactRequiredFields) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [
    setIsSubmitDisabled,
    venueName,
    venueType,
    venueCity,
    venueRegion,
    venueCountry,
    liveshotName,
  ]);

  const updateFormInfo = () => {
    if (formInfo) {
      setLiveshotNotes(formInfo.liveshotNote);
      setHitTime(formInfo.hitTime);
      setVenueName(formInfo.venue);
      setStudioLocation(formInfo.abstractLiveshot.location);
      setVenueContact(formInfo.liveshotContactName);
      setVenuePhone(formInfo.liveshotContactPhone);
      setVenueEmail(formInfo.liveshotContactEmail);
      setRecord(formInfo.globalSignal);
      setStudioDefaultTransmissionPath(formInfo.tx);
      setVenueType(formInfo.venueType);
      setVenueCity(formInfo.city);
      setVenueCountry(formInfo.country);
      setVenueRegion(formInfo.region);
      setVenueSkype(formInfo.skype);
      setVenueQuicklink(formInfo.quickLink);
      setVenueWebOther(formInfo.webOther);
      setLocation(formInfo.abstractLiveshot.location);
      setLiveshotName(formInfo.liveshotName);
      setWindowStartTime(formInfo.windowStartTime);
      setWindowEndTime(formInfo.windowEndTime);
      setPoNo(formInfo.poNumber);
      setHitNote(formInfo.hitNote);

      let expdate = formInfo.expiration ? new Date(formInfo.expiration) : null;
      setExpiration(expdate);
      setIsPermanentLiveshot(formInfo.permanentLiveshot);
      setIsHostEnabled(formInfo.hostEnabled);
      setReadOnly(formInfo.abstractLiveshot.readOnly);
      if (formInfo.readyForPcr) {
        setIsReadyPcr(true);
      }
    }
  };

  const updateAbstractFormInfo = () => {
    const absFormObj = {
      abstractLiveshot: {},
    };
    absFormObj.liveshotId = formInfo.liveshotId;
    absFormObj.liveshotName = liveshotName;
    absFormObj.city = venueCity;
    absFormObj.country = venueCountry;
    absFormObj.expiration = formInfo.abstractLiveshot.expiration
      ? new Date(formInfo.abstractLiveshot.expiration)
      : null;
    absFormObj.hitNote = null;
    absFormObj.liveshotNote = liveshotNotes;
    absFormObj.poNo = poNo;
    absFormObj.quickLink = venueQuicklink;
    absFormObj.region = venueRegion;
    absFormObj.skype = venueSkype;
    absFormObj.stId = formInfo.abstractLiveshot.stId;
    absFormObj.liveshotContactName = venueContact;
    absFormObj.liveshotContactPhone = venuePhone;
    absFormObj.liveshotContactEmail = venueEmail;
    absFormObj.studioName = venueName;
    absFormObj.tx = studioDefaultTransmissionPath;
    absFormObj.updatedBy = currentUser.sso;
    absFormObj.updatedOn = moment().utc().format();
    absFormObj.venue = venueName;
    absFormObj.venueType = venueType;
    absFormObj.webOther = venueWebOther;
    absFormObj.windowEndTime = windowEndTime;
    absFormObj.hitTime = hitTime;
    absFormObj.windowStartTime = windowStartTime;
    absFormObj.hostEnabled = isHostEnabled;

    absFormObj.abstractLiveshot.abstractLiveshotName = liveshotName;
    absFormObj.abstractLiveshot.city = venueCity;
    absFormObj.abstractLiveshot.country = venueCountry;
    absFormObj.abstractLiveshot.expiration = formInfo.abstractLiveshot
      .expiration
      ? new Date(formInfo.abstractLiveshot.expiration)
      : null;
    absFormObj.abstractLiveshot.hitNote = null;
    absFormObj.abstractLiveshot.liveshotNote = liveshotNotes;
    absFormObj.abstractLiveshot.poNo = poNo;
    absFormObj.abstractLiveshot.quickLink = venueQuicklink;
    absFormObj.abstractLiveshot.region = venueRegion;
    absFormObj.abstractLiveshot.skype = venueSkype;
    absFormObj.abstractLiveshot.stId = formInfo.abstractLiveshot.stId;
    absFormObj.abstractLiveshot.studioContactName = venueContact;
    absFormObj.abstractLiveshot.studioContactPhone = venuePhone;
    absFormObj.abstractLiveshot.studioContactEmail = venueEmail;
    absFormObj.abstractLiveshot.studioName = venueName;
    absFormObj.abstractLiveshot.studioTx = studioDefaultTransmissionPath;
    absFormObj.abstractLiveshot.updatedBy = currentUser.sso;
    absFormObj.abstractLiveshot.updatedOn = moment().utc().format();
    absFormObj.abstractLiveshot.venue = venueName;
    absFormObj.abstractLiveshot.venueType = venueType;
    absFormObj.abstractLiveshot.webOther = venueWebOther;
    absFormObj.abstractLiveshot.windowEndTime = windowEndTime;
    absFormObj.abstractLiveshot.windowHitTime = hitTime;
    absFormObj.abstractLiveshot.windowStartTime = windowStartTime;
    absFormObj.abstractLiveshot.hostEnabled = isHostEnabled;

    if (venueName && liveshotName) {
      onSubmitAbstractLiveshot(
        absFormObj,
        token,
        showNotificationBar,
        currentCanvasId,
        formInfo.abstractLiveshot.abstractLiveshotId
      );
    } else if (venueName === null || venueName === "") {
      showNotificationBar(
        liveshot_canvas_constants.ABS_VENUE_SUBMIT_ERROR_MESSAGE,
        true
      );
    } else {
      showNotificationBar(
        liveshot_canvas_constants.ABS_SUBMIT_ERROR_MESSAGE,
        true
      );
    }
  };
  useEffect(() => {
    fetchPinModalInfo(token);
  }, [token]);

  const onCloseCitySuggestion = () => {
    setShowCitySuggestion(false);
  };
  let filteredPinList = null;
  let selectedNamelist = null;
  let pinNames = [];
  if (liveshotName && pinModalInfo) {
    pinModalInfo.forEach((pm) => pinNames.push(pm.liveshotName));
    pinModalInfo.map((pinList) => {
      filteredPinList = pinNames.filter((name) => {
        if (name !== null) {
          return name.toLowerCase().includes(liveshotName.toLowerCase());
        } else {
          return name;
        }
      });
      if (filteredPinList.length > 0) {
        selectedNamelist = (
          <LiveshotNameSuggestion
            LSNameLists={filteredPinList}
            showsuggestion={showsuggestion}
            closeSuggestion={onCloseSuggestion}
            selectLiveshotName={(name) => {
              setLiveshotName(name);
              let defaultSignal = pinTableInfo.find(
                (item) => item.liveshotName === name
              );
              updateLiveshotData({
                liveshotName: name,
                updatedBy: parseInt(userId),
              });
              if (
                stId === 0 &&
                pinTableInfo.length > 0 &&
                defaultSignal !== undefined
              ) {
                setDefaultCommSignals(defaultSignal);
              }
            }}
          />
        );
      }
    });
  }

  const resetDefaultCommSignal = () => setDefaultCommSignals(null);

  const onChangeType = (e) => {
    const arrOptions = ["skype", "facetime", "zoom", "qlink"];
    let option = e.target.options[e.target.selectedIndex].text.toLowerCase();
    setStype(e.target.value);
    if (arrOptions.includes(option)) {
      document.querySelector(".inbound-create-btn").click();
    }
    onPatchStudioTruck({ studioTypeId: e.target.value }, token, stId);
  };

  const onChangeIfbpath = (e) => {
    setStudioDefaultTransmissionPath(e.target.value);
  };

  const onViewLiveShotInfo = (e) => {
    if (!newliveshot && !liveShotInfoWrap) {
      onShowLiveShotInfo(e);
    } else if (!newliveshot && liveShotInfoWrap) {
      onUnShowLiveShotInfo(e, liveShotInfoWrap);
    }
  };

  const trimCrAndPcFromPathName = (pathName) => {
    var patt = /^[cv]r ?\d\w pc /i;
    var trimmedName = pathName;
    if (pathName && pathName !== null) {
      if (pathName.match(patt)) trimmedName = pathName.replace(patt, "");
    }
    return trimmedName;
  };

  const trimCrFromPathName = (pathName) => {
    if (pathName === null) return;
    var patt = /^[cv]r ?\d\w /i;
    var trimmedName = pathName;
    if (pathName.match(patt)) trimmedName = pathName.replace(patt, "");
    return trimmedName;
  };

  const onChangeSignal = (inBound, outBound, comms) => {
    setFirstIfbGlobalPath(null);
    setFirstPlGlobalPath(null);
    setFirstPhoGlobalPath(null);
    setInBoundFields(inBound);
    setOutBoundFields(outBound);
    setCommsFields(comms);
  };

  const updateInfo = (
    <div className="update-info-content">
      <div className="update-info-abstractliveshotId">
        <span>AbstractLiveshot Id: </span>
        {formInfo ? formInfo.abstractLiveshot.abstractLiveshotId : ""}
      </div>
      <div className="update-info-abstractUpdated-by">
        <span>Updated By: </span>
        {abstractUserName}
      </div>
      <div className="update-info-abstarctUpdated-on">
        <span>Updated On: </span>
        {formInfo && formInfo.abstractLiveshot.updatedOn
          ? new Date(formInfo.abstractLiveshot.updatedOn).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })
          : ""}
      </div>
      <div className="update-info-liveshotId">
        <span>Liveshot id: </span> {formInfo ? formInfo.liveshotId : ""}
      </div>
      <div className="update-info-updated-by">
        <span>Updated by: </span> {userName}
      </div>
      <div className="update-info-updated-on">
        <span>Updated On: </span>
        {formInfo && formInfo.updatedOn
          ? new Date(formInfo.updatedOn).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })
          : ""}
      </div>
    </div>
  );

  let duplicateLiveshot = null;
  if (deleteLiveshotPerm && formInfo) {
    duplicateLiveshot = <DuplicateLiveshot liveshotInfo={formInfo} />;
  }

  const onChangeHitNotes = (event) => {
    const formData = {};
    formData[event.target.name] = event.target.value;
    formData.updatedBy = parseInt(userId);
    if (event.target.name === "hitNote") {
      setHitNote(event.target.value);
    }
    updateLiveshotData(formData);
  };

  const onChangeLiveshotNotes = (event) => {
    const formData = {};
    formData[event.target.name] = event.target.value;
    formData.updatedBy = parseInt(userId);
    if (event.target.name === "liveshotNote") {
      setLiveshotNotes(event.target.value);
    }
    updateLiveshotData(formData);
  };

  const updatePin = (e, type) => {
    if (!e.target.value) return;
    switch (type) {
      case "IFB":
        if (firstIfbGlobalPath) {
          setIfbPin(e.target.value);
          onSetAccessPin(token, firstIfbGlobalPath, e.target.value);
        }
        break;
      case "PL":
        if (firstPlGlobalPath) {
          setPlPin(e.target.value);
          onSetAccessPin(token, firstPlGlobalPath, e.target.value);
        }
        break;
      case "PHO":
        if (firstPhoGlobalPath) {
          setPhoPin(e.target.value);
          onSetAccessPin(token, firstPhoGlobalPath, e.target.value);
        }
        break;
      default:
        setPhoPin(e.target.value);
    }
  };

  const changePinFromSignals = (value, type) => {
    if (type === "IFB") {
      setIfbPin(value);
    }
    if (type === "PL") {
      setPlPin(value);
    }
    if (type === "PHO") {
      setPhoPin(value);
    }
  };

  const resetAllPin = () => {
    setIfbPin("");
    setPlPin("");
    setPhoPin("");
  };

  const handleDrop = (sip) => {
    if (updateLiveshotPerm || manageLiveshotSignalPerm) {
      // Drop sip pill
      onDropSip(token, sip);
    }
  };

  let liveshotTypeName = null;
  if (Number(liveshotTypeId) === 1) {
    liveshotTypeName = "Local Host";
  } else if (Number(liveshotTypeId) === 2) {
    liveshotTypeName = "Beauty Shot";
  } else if (Number(liveshotTypeId) === 3) {
    liveshotTypeName = "Local Guest";
  } else if (Number(liveshotTypeId) === 4) {
    liveshotTypeName = "Remote Host";
  } else if (Number(liveshotTypeId) === 5) {
    liveshotTypeName = "Remote Guest";
  } else if (Number(liveshotTypeId) === 6) {
    liveshotTypeName = "Remote Reporter";
  } else if (Number(liveshotTypeId) === 7) {
    liveshotTypeName = "Remote Event";
  } else if (Number(liveshotTypeId) === 9) {
    liveshotTypeName = "Edit/Playback";
  } else {
    liveshotTypeName = "Live Shot";
  }

  let icon = null;
  switch (liveshotTypeName) {
    case "Local Host":
    case "Remote Host":
      icon = HostIcon;
      break;
    case "Beauty Shot":
      icon = StudioIcon;
      break;
    default:
      icon = null;
  }

  let send_receive_elements = [];

  let comms_elements = [];
  let local_elements = [];
  let ifb_elements = [];
  let pl_elements = [];
  let pho_elements = [];
  if (inBoundFields) {
    inBoundFields.forEach((ib) => {
      if (
        ib.globalPathName !== null &&
        ib.globalPathName !== "" &&
        send_receive_elements.length < 3
      ) {
        send_receive_elements.push(ib.globalPathName);
      }
      if (
        ib.crPathName !== null &&
        ib.crPathName !== "" &&
        local_elements.length < 3
      ) {
        local_elements.push(ib.crPathName);
        local_elements.push(<span key="icn" className="comms-ellip"></span>);
      }
    });
  }
  if (outBoundFields) {
    outBoundFields.forEach((ob) => {
      if (
        ob.crPathName !== null ||
        (ob.crPathName !== "" && local_elements.length < 3)
      ) {
        local_elements.push(ob.crPathName);
        local_elements.push(<span key="icn" className="comms-ellip"></span>);
      }
    });
  }
  if (ifbCommPaths.length > 0) {
    ifb_elements = ifbCommPaths.slice(0, 3);
  }

  if (plCommsPaths.length > 0) {
    pl_elements = plCommsPaths.slice(0, 3);
  }
  if (phoCommsPaths.length > 0) {
    pho_elements = phoCommsPaths.slice(0, 3);
  }

  if (commsPaths.length > 0) {
    comms_elements.push(commsPaths[0]);
    comms_elements.push(commsPaths[1]);
    comms_elements.push(commsPaths[2]);
    if (commsPaths.length > 3)
      comms_elements.push(
        <span key="icn" className="comms ellip">
          &#8943;
        </span>
      );
  }
  const closeModal = () => {
    setShowModal(false);
  };

  const moveLiveshotUp = () => {
    let sequenceNo = formInfo.sequenceNo;
    const blockLiveshots = (normalLiveShots || []).find(
      (val) => formInfo.blockId === val.blockId
    );
    if (sequenceNo && normalLiveShots.length > 0 && blockLiveshots) {
      if (blockLiveshots.liveshots.length > 0) {
        let normalLiveShotsList = blockLiveshots.liveshots.filter(
          (normalLiveShots) => normalLiveShots.softDelete !== true
        );
        let prevSeq = parseInt(sequenceNo) - 1;
        let previousLiveshot = normalLiveShotsList.find((liveshot) => {
          return (
            liveshot.softDelete !== true && liveshot.sequenceNo === prevSeq
          );
        });
        if (previousLiveshot && previousLiveshot.liveshotId) {
          let payload = { sequenceNo: previousLiveshot.sequenceNo };
          sequenceNo = parseInt(previousLiveshot.sequenceNo) + 1;
          onLiveshotUpdate(
            parseInt(userId),
            currentCanvasId,
            formInfo.liveshotId,
            payload,
            token,
            previousLiveshot ? false : true
          );
          let prevPayload = { sequenceNo: sequenceNo };
          onLiveshotUpdate(
            parseInt(userId),
            currentCanvasId,
            previousLiveshot.liveshotId,
            prevPayload,
            token
          );
        }
      }
    }
  };

  const moveLiveshotDown = () => {
    let sequenceNo = formInfo.sequenceNo;
    const blockLiveshots = (normalLiveShots || []).find(
      (val) => formInfo.blockId === val.blockId
    );
    if (
      (sequenceNo || sequenceNo === 0) &&
      normalLiveShots.length > 0 &&
      blockLiveshots
    ) {
      if (blockLiveshots.liveshots.length > 0) {
        let normalLiveShotsList = blockLiveshots.liveshots.filter(
          (normalLiveShots) => normalLiveShots.softDelete !== true
        );
        let nextSeq = parseInt(sequenceNo) + 1;
        let previousLiveshot = normalLiveShotsList.find((liveshot) => {
          return (
            liveshot.softDelete !== true && liveshot.sequenceNo === nextSeq
          );
        });
        if (previousLiveshot && previousLiveshot.liveshotId) {
          let payload = { sequenceNo: previousLiveshot.sequenceNo };
          sequenceNo = parseInt(previousLiveshot.sequenceNo) - 1;
          onLiveshotUpdate(
            parseInt(userId),
            currentCanvasId,
            formInfo.liveshotId,
            payload,
            token,
            previousLiveshot ? false : true
          );
          let prevPayload = { sequenceNo: sequenceNo };

          onLiveshotUpdate(
            parseInt(userId),
            currentCanvasId,
            previousLiveshot.liveshotId,
            prevPayload,
            token
          );
        }
      }
    }
  };

  let showError = null;
  if (showModal) {
    showError = (
      <Fragment>
        <Backdrop show={showModal} clicked={closeModal} />
        <ErrorMessage closeModal={closeModal} message={showMessage} />
      </Fragment>
    );
  }
  const handleOnClear = () => {
    setVenueCity("");
    setVenueRegion("");
    setVenueCountry("");
    setVenueSkype("");
    setVenueQuicklink("");
    setVenueWebOther("");
    setVenueType("");
    setVenueName("");
    setStudioDefaultTransmissionPath("");
    setLiveshotNotes("");
    setVenueContact("");
    setVenuePhone("");
    setVenueEmail("");
    setPoNo("");
    setStId(0);
    onResetStudioTruckData();
    updateLiveshotData({
      venue: "",
      venueType: "",
      city: "",
      region: "",
      country: "",
      skype: "",
      quickLink: "",
      webOther: "",
      tx: "",
      poNumber: "",
      liveshotContactName: "",
      liveshotContactPhone: "",
      liveshotContactEmail: "",
      liveshotNote: "",
      updatedBy: parseInt(userId),
      stId: 0,
    });
  };

  const handleChangeDate = (value) => {
    const selectedValue = value;
    const newDate = new Date();
    selectedValue.setHours(newDate.getHours());
    selectedValue.setMinutes(newDate.getMinutes());
    selectedValue.setSeconds(newDate.getSeconds());
    setIsCalendarOpen(false);
    const formData = {};
    setExpiration(value);
    if (value) {
      // Setting time to expire at 1 AM EST
      let expDate = parseDate(selectedValue);
      setFormatted_date(expDate);
      formData.expiration = expDate;
      formData.permanentLiveshot = false;
    } else {
      setFormatted_date(null);
      formData.expiration = "";
      formData.permanentLiveshot = true;
    }
    setIsPermanentLiveshot(formData.permanentLiveshot);
    setIsExtendActive(false);
    formData.updatedBy = parseInt(userId);
    updateLiveshotData(formData);
  };
  const cancelDelete = () => {
    setIsDeleteIconClicked(false);
  };
  const deleteLiveshot = () => {
    let sequenceNo = formInfo.sequenceNo;
    const blockLiveshots = (normalLiveShots || []).find(
      (val) => formInfo.blockId === val.blockId
    );
    if (sequenceNo && normalLiveShots.length > 0 && blockLiveshots) {
      if (blockLiveshots.liveshots.length > 0) {
        let normalLiveShotsList = blockLiveshots.liveshots.filter(
          (normalLiveShots) => normalLiveShots.softDelete !== true
        );
        if (sequenceNo > 0) {
          let slicedArr = normalLiveShotsList.slice(sequenceNo) || [];
          slicedArr.map((liveshot) => {
            onLiveshotUpdate(
              parseInt(userId),
              currentCanvasId,
              liveshot.liveshotId,
              { sequenceNo: liveshot.sequenceNo - 1 },
              token,
              false
            );
          });
        }
      }
    }
    onDeleteLiveShot(formInfo.liveshotId, currentCanvasId, userId, token);
    setIsDeleteIconClicked(false);
  };
  const onDeleteCurrentLiveShot = () => {
    setIsDeleteIconClicked(true);
    setConfirmationDeleteMessage(DELETE_LIVESHOT_MESSAGE);
  };

  const onAddObjectExpiration = () => {
    let expirationDate;
    if (expiration && new Date(expiration) > new Date()) {
      expirationDate = new Date(expiration);
    } else {
      expirationDate = new Date();
    }
    let nextDate = expirationDate.setDate(expirationDate.getDate() + 1);
    setExpiration(new Date(nextDate));
    setIsPermanentLiveshot(false);
    let expNextDate = parseDate(nextDate);
    setFormatted_date(expNextDate);
    updateLiveshotData({
      expiration: expNextDate,
      permanentLiveshot: false,
      updatedBy: parseInt(userId),
    });
  };

  const onExtendPermanetly = () => {
    setIsExtendActive(true);
    // set permanentLiveshot as false, which specfies this on API as a permanent liveshot
    setIsPermanentLiveshot(true);
    setExpiration(new Date());
    updateLiveshotData({
      permanentLiveshot: true,
      expiration: parseDate(),
      updatedBy: parseInt(userId),
    });
  };

  const getSelectedStatusOption = () => {
    return options.filter((option) => {
      return option.value === buttonState;
    });
  };

  const showNotificationBar = (message, isError) => {
    showCommonNotificationBar({ show: true, content: message, isError });
  };

  const onClearDate = () => {
    setExpiration("");
    setIsPermanentLiveshot(true);
    updateLiveshotData({
      expiration: "",
      permanentLiveshot: true,
      updatedBy: parseInt(userId),
    });
  };

  let notAllowTakeAll = false;
  let notAllowGrabAll = false;
  if (
    isArmRouting === false ||
    inBoundFields.length === 0 ||
    !takeAllPerm ||
    ![Constants.READY_FOR_CONTROL_ROOM, Constants.READY_FOR_AIR].includes(
      buttonState
    )
  ) {
    notAllowTakeAll = true;
  }

  if (
    isArmRouting === false ||
    (outBoundFields.length === 0 && commsFields.length === 0) ||
    !grabAllPerm ||
    ![Constants.READY_FOR_CONTROL_ROOM, Constants.READY_FOR_AIR].includes(
      buttonState
    )
  ) {
    notAllowGrabAll = true;
  }

  const closeErrorModal = () => {
    onHideSignalErrorModal();
  };

  let showSignalError = null;
  if (showSignalErrorModal) {
    showSignalError = (
      <Fragment>
        <Backdrop show={showSignalErrorModal} clicked={closeErrorModal} />
        <CommanModal closeModal={closeErrorModal} message={signalErrorMsg} />
      </Fragment>
    );
  }
  if (pinError) {
    showSignalError = (
      <Fragment>
        <Backdrop show={true} clicked={onResetPinError} />
        <ErrorMessage closeModal={onResetPinError} message={pinError} />
      </Fragment>
    );
  }

  let commPills = null;
  if (commsFields && routingCapabilityType >= 2) {
    commPills = commsFields.map((comms, id) => {
      let hookValue = true;
      let pinInfo;
      if (comms.globalPathName) {
        pinInfo = globalPins.find(
          (item) => item.namesetName === comms.globalPathName
        );
        if (pinInfo) {
          if (pinInfo.participants > 0) {
            hookValue = false;
          } else {
            hookValue = true;
          }
        }
      }
      let manageBtn = true;
      const grabbed = grabbedSignals.filter(
        (item) => item.liveshotSignalId === comms.liveshotSignalId
      );
      if (grabbed.length > 0) {
        if (
          parseInt(grabbed[0].controlledBy) === parseInt(userId) &&
          grabbed[0].controlType === 1
        ) {
          manageBtn = false;
        }
      }
      return comms.globalPathName !== null &&
        comms.globalPathName !== "" &&
        pinInfo ? (
        <CommPills
          key={id}
          hookValue={hookValue}
          globalPathName={comms.globalPathName}
          isMangeDisable={
            manageBtn || !manageLiveshotSignalPerm
              ? manageBtn || !manageLiveshotSignalPerm
              : !comms.uniLateral
          }
          currentUserRole={currentUserRole}
        />
      ) : (
        ""
      );
    });
  }

  let outBoundManagePills = null;
  if (outBoundFields) {
    outBoundManagePills = outBoundFields.map((field, id) => {
      let manageBtn = true;
      const grabbed = grabbedSignals.filter(
        (item) => item.liveshotSignalId === field.liveshotSignalId
      );
      if (grabbed.length > 0) {
        if (
          parseInt(grabbed[0].controlledBy) === parseInt(userId) &&
          grabbed[0].controlType === 1
        ) {
          manageBtn = false;
        }
      }
      let managementUrl = "";
      let disableManagement = true;
      if (routerDestinations.length > 0 && field.globalPathName) {
        let routerDestinationValue = routerDestinations.find(
          (signal) => signal.namesetName === field.globalPathName
        );
        if (routerDestinationValue && routerDestinationValue !== undefined) {
          if (routerDestinationValue.managementUrl) {
            managementUrl = routerDestinationValue.managementUrl;
            disableManagement = false;
          }
        }
      }
      return field.globalPathName !== null &&
        field.globalPathName !== "" &&
        managementUrl ? (
        <OutBoundManagePills
          key={id}
          isMangeDisable={
            manageBtn || disableManagement || !manageLiveshotSignalPerm
              ? manageBtn || disableManagement || !manageLiveshotSignalPerm
              : !field.uniLateral
          }
          globalPathName={field.globalPathName}
          managementUrl={managementUrl}
          currentUserRole={currentUserRole}
        />
      ) : (
        ""
      );
    });
  }

  const Overlay = (
    <span className="overlay-icon">{liveShotInfoWrap ? <Up /> : <Down />}</span>
  );

  /* Block Liveshot Logic */
  let updateError = null;
  if (showErrorModal) {
    updateError = (
      <Fragment>
        <Backdrop show={showErrorModal} clicked={onHideErrorModal} />
        <ErrorMessage closeModal={onHideErrorModal} message={errorMsg} />
      </Fragment>
    );
  }

  const handleHostChange = (e) => {
    const isEnabled = e.target.checked;
    const isCanvasTemplateSelected = canvasTemplateSelected ? true : false;
    let expNextDate = null;

    if (!isEnabled && !isCanvasTemplateSelected) {
      let expirationDate = new Date();
      let nextDate = expirationDate.setDate(expirationDate.getDate() + 1);
      setExpiration(new Date(nextDate));
      expNextDate = parseDate(nextDate);
      setFormatted_date(expNextDate);
    }
    setIsHostEnabled(isEnabled);
    setIsPermanentLiveshot(isEnabled || isCanvasTemplateSelected);
    updateLiveshotData({
      hostEnabled: isEnabled,
      permanentLiveshot: isEnabled || isCanvasTemplateSelected,
      updatedBy: parseInt(userId),
      ...(!isEnabled &&
        !isCanvasTemplateSelected && { expiration: expNextDate }),
    });
  };

  return (
    <>
      <PopupModal
        showModal={isInvalidSubmit}
        onModalClose={() => {
          setIsInvalidSubmit(false);
        }}
        title={Constants.ERROR_HEADING}
        content={Constants.ERROR_MANDATORY_FIELDS_MISSING}
        type={"error"}
      />
      <ConfirmationModal
        isModalOpen={isDeleteIconClicked}
        cancelDelete={cancelDelete}
        deleteRow={deleteLiveshot}
        message={confirmationDeleteMessage}
      ></ConfirmationModal>
      <Loader loading={loading} />
      <Draggable
        draggableId={formInfo ? formInfo.liveshotId.toString() : "new-liveshot"}
        index={formInfo ? index : -1}
        isDragDisabled={!blockPerm}
      >
        {(provided, snapshot) => (
          <div
            className="container"
            id={id}
            blockid={formInfo ? formInfo.blockId : null}
            sequenceno={formInfo ? formInfo.sequenceNo : 0}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
              borderColor: snapshot.isDragging ? "#87D1AE" : "#1D1D1D",
              ...provided.draggableProps.style,
            }}
          >
            {updateError}
            {showError}
            {showSignalError}
            <div>
              <div className={blockPerm ? "verbars" : "hide-delete"}>
                <AntdTooltip
                  title="MOVE SUMMARY ROW"
                  placement="right"
                  align={{ offset: [10, 0] }}
                >
                  <i>
                    <GrabberIcon className="grabber-icon" />
                  </i>
                </AntdTooltip>
                <div
                  className={blockPerm ? "moveUp" : "hide-delete"}
                  onClick={moveLiveshotUp}
                >
                  <AntdTooltip
                    title="MOVE UP"
                    placement="right"
                    align={{ offset: [10, 0] }}
                  >
                    <UpIcon />
                  </AntdTooltip>
                </div>
                <div
                  className={blockPerm ? "moveDown" : "hide-delete"}
                  onClick={moveLiveshotDown}
                >
                  <AntdTooltip
                    title="MOVE DOWN"
                    placement="right"
                    align={{ offset: [10, 0] }}
                  >
                    <DownIcon />
                  </AntdTooltip>
                </div>
                {duplicateLiveshot}
                <div
                  className={
                    newliveshot || !deleteLiveshotPerm
                      ? "hide-delete"
                      : "remove-liveshot"
                  }
                >
                  <AntdTooltip
                    title="DELETE"
                    placement="right"
                    align={{ offset: [10, 0] }}
                  >
                    <RemoveIcon
                      onClick={() => onDeleteCurrentLiveShot()}
                      disabled={false}
                    />
                  </AntdTooltip>
                </div>
              </div>
              <form
                onSubmit={afterSubmitHandler}
                ref={
                  formInfo.liveshotId === activeLiveshot ? activeSlideRef : null
                }
                className={
                  formInfo.liveshotId === activeLiveshot
                    ? "form-inline highlight"
                    : "form-inline"
                }
                autoComplete="off"
              >
                <div className="top-canvas-row-normalobject">
                  <div
                    className={
                      liveshotName
                        ? "top-canvas-row-names-normalobject top-canvas-normalliveshot"
                        : "top-canvas-row-names-normalobject"
                    }
                  >
                    <label>{trimLabel(liveshotName)}</label>
                    <label>{trimLabel(venueName)}</label>
                  </div>
                  <div className="top-canvas-row-pills-normalobject">
                    <div className="sip-pill-container">
                      <label className="comms-labels-summary-row">
                        {getSipPill(sipPills["ifbSip1"], "pathName") || "IFB 1"}
                      </label>
                      <ArrowTooltip
                        title={
                          manageLiveshotSignalPerm &&
                          sipState[getSipPill(sipPills["ifbSip1"], "sip")]
                        }
                        onClick={() =>
                          handleDrop(getSipPill(sipPills["ifbSip1"], "sip"))
                        }
                      >
                        <div
                          className={getActiveSipClass(
                            sipState,
                            getSipPill(sipPills["ifbSip1"], "sip"),
                            manageLiveshotSignalPerm
                          )}
                        >
                          <label className="comms-labels-circle-text">
                            {getSipLabel(sipPills["ifbSip1"])}
                          </label>
                        </div>
                      </ArrowTooltip>
                    </div>
                    <div className="sip-pill-container">
                      <label className="comms-labels-summary-row">
                        {getSipPill(sipPills["ifbSip2"], "pathName") || "IFB 2"}
                      </label>
                      <ArrowTooltip
                        title={
                          manageLiveshotSignalPerm &&
                          sipState[getSipPill(sipPills["ifbSip2"], "sip")]
                        }
                        onClick={() =>
                          handleDrop(getSipPill(sipPills["ifbSip2"], "sip"))
                        }
                      >
                        <div
                          className={getActiveSipClass(
                            sipState,
                            getSipPill(sipPills["ifbSip2"], "sip"),
                            manageLiveshotSignalPerm
                          )}
                        >
                          <label className="comms-labels-circle-text">
                            {getSipLabel(sipPills["ifbSip2"])}
                          </label>
                        </div>
                      </ArrowTooltip>
                    </div>
                    <div className="sip-pill-container">
                      <label className="comms-labels-summary-row">
                        {getSipPill(sipPills["plSip"], "pathName") || "PL"}
                      </label>
                      <ArrowTooltip
                        title={
                          manageLiveshotSignalPerm &&
                          sipState[getSipPill(sipPills["plSip"], "sip")]
                        }
                        onClick={() =>
                          handleDrop(getSipPill(sipPills["plSip"], "sip"))
                        }
                      >
                        <div
                          className={getActiveSipClass(
                            sipState,
                            getSipPill(sipPills["plSip"], "sip"),
                            manageLiveshotSignalPerm
                          )}
                        >
                          <label className="comms-labels-circle-text">
                            {getSipLabel(sipPills["plSip"])}
                          </label>
                        </div>
                      </ArrowTooltip>
                    </div>
                    <div className="sip-pill-container">
                      <label className="comms-labels-summary-row">
                        {getSipPill(sipPills["phoSip"], "pathName") || "PHO"}
                      </label>
                      <ArrowTooltip
                        title={
                          manageLiveshotSignalPerm &&
                          sipState[getSipPill(sipPills["phoSip"], "sip")]
                        }
                        onClick={() =>
                          handleDrop(getSipPill(sipPills["phoSip"], "sip"))
                        }
                      >
                        <div
                          className={getActiveSipClass(
                            sipState,
                            getSipPill(sipPills["phoSip"], "sip"),
                            manageLiveshotSignalPerm
                          )}
                        >
                          <label className="comms-labels-circle-text">
                            {getSipLabel(sipPills["phoSip"])}
                          </label>
                        </div>
                      </ArrowTooltip>
                    </div>
                    <div className="comm-pills-row-normalobject">
                      {commPills}
                    </div>
                    <div className="outbound-pills-row-normalobject">
                      {outBoundManagePills}
                    </div>
                  </div>

                  <div
                    className={[liveShotInfoLabel ? "show" : "hidden"].join(
                      " "
                    )}
                  >
                    <AntdTooltip title={"EXPAND LIVE SHOT"}>
                      <label
                        className="downIcon unfold_live_shots"
                        onClick={(e) => onShowLiveShotInfo(e)}
                      >
                        <CollapseDownIcon className="collapseDownIcon" />
                      </label>
                    </AntdTooltip>
                  </div>
                  <div className={liveShotInfoWrap ? "info" : "hidden"}>
                    <AntdTooltip title={"COLLAPSE LIVE SHOT"}>
                      <label
                        className={
                          liveShotInfoWrap
                            ? "upIcon fold_live_shots foldunfoldhide"
                            : "upIcon foldunfoldhide"
                        }
                        onClick={(e) =>
                          onUnShowLiveShotInfo(e, liveShotInfoWrap)
                        }
                      >
                        <CollapseUpIcon />
                      </label>
                    </AntdTooltip>
                  </div>
                </div>

                <div className="row canvas-row">
                  <div
                    className={`form-group startTimeBox ${getPathClassName(
                      validStartTime,
                      liveShotInfoWrap
                    )}`}
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="input-group">
                      <label>Start Time</label>
                      <div
                        className={`windowtime ${
                          !windowStartTime && "placeholder-text"
                        }`}
                      >
                        {windowStartTime ? windowStartTime : "HH:MM AM/PM"}
                      </div>
                    </div>
                    {Overlay}
                  </div>
                  <div
                    className={`form-group endTimeBox ${getPathClassName(
                      validEndTime,
                      liveShotInfoWrap
                    )}`}
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="input-group">
                      <label>End Time</label>
                      <div
                        className={`windowtime ${
                          !windowEndTime && "placeholder-text"
                        }`}
                      >
                        {windowEndTime ? windowEndTime : "HH:MM AM/PM"}
                      </div>
                    </div>
                    {Overlay}
                  </div>

                  <div
                    className={`form-group hitTimeBox ${getPathClassName(
                      validHitTime,
                      liveShotInfoWrap
                    )}`}
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="input-group">
                      <label>Hit Time</label>
                      <div
                        className={`hittime ${!hitTime && "placeholder-text"}`}
                      >
                        {hitTime ? hitTime : "HH:MM AM/PM"}
                      </div>
                    </div>
                    {Overlay}
                  </div>

                  <div
                    className="form-group studio contactBox"
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="input-group">
                      <label>Contact </label>
                      <DebounceInput
                        type="text"
                        debounceTimeout={1500}
                        className="form-control venuecontact"
                        name="venuecontact"
                        value={venueContact || ""}
                        onChange={onChangeVenueContactName}
                        placeholder="NAME"
                        readOnly={true}
                      />
                      <DebounceInput
                        type="text"
                        className="form-control venuephone"
                        name="venuephone"
                        debounceTimeout={1500}
                        value={venuePhone || ""}
                        onChange={onChangeVenueContactPhone}
                        placeholder="PHONE"
                        readOnly={true}
                      />
                    </div>
                    {Overlay}
                  </div>

                  <div
                    className="form-group studio txBox"
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="input-group">
                      <label>Tx </label>

                      {/* tx id number */}
                      <div className="col-content">
                        {getSummaryRow(summaryPath, "tx")}
                      </div>
                    </div>
                    {Overlay}
                  </div>
                  <div
                    className={
                      "form-group studio ifbBox" +
                      getPathClassName(invalidIfbPath, liveShotInfoWrap)
                    }
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="input-group">
                      <label>IFB </label>
                      <div className="col-content">
                        {ifb_elements.join("\n")}
                      </div>
                    </div>
                    {Overlay}
                  </div>
                  <div
                    className={
                      "form-group studio plBox" +
                      getPathClassName(invalidPlPath, liveShotInfoWrap)
                    }
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="input-group">
                      <label>PL </label>
                      <div className="col-content">
                        {pl_elements.join("\n")}
                      </div>
                    </div>
                    {Overlay}
                  </div>
                  <div
                    className={
                      "form-group studio phonerBox" +
                      getPathClassName(invalidPhoPath, liveShotInfoWrap)
                    }
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="input-group">
                      <label>Phoner </label>
                      <div className="col-content">
                        {pho_elements.join("\n")}
                      </div>
                    </div>
                    {Overlay}
                  </div>
                  <div
                    className={
                      "form-group-hoststudio send_receive_box" +
                      getPathClassName(invalidGlobalPath, liveShotInfoWrap)
                    }
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="comms-canvas-right">
                      <div className="send_receive_box_div">
                        <label>Global</label>
                        <span className="record_icon">
                          {" "}
                          <div>
                            <AntdTooltip title="Select to indicate recording needed">
                              <input
                                onClick={recordToggle}
                                className="record"
                                type="checkbox"
                                checked={record}
                                disabled={!updateLiveshotStatusPerm}
                                aria-hidden="true"
                              />
                            </AntdTooltip>
                          </div>
                        </span>
                        {outBoundFields.length > 0 ? (
                          <img
                            className="global_satellite_icon"
                            src={globalSatelliteIcon}
                          />
                        ) : null}
                        {Overlay}
                      </div>
                      <div className="col-content">
                        {getSummaryRow(summaryPath, "globalPathName")}
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "form-group-hoststudio comms_box" +
                      getPathClassName(invalidCrPath, liveShotInfoWrap)
                    }
                    onClick={(e) => onViewLiveShotInfo(e)}
                  >
                    <div className="comms-canvas-right">
                      <div className="local_comms_box_div">
                        <label>Local</label>
                      </div>
                      <div className="col-content">
                        {getSummaryRow(summaryPath, "crPathName")}
                      </div>
                      {Overlay}
                    </div>
                  </div>
                  <SelectDropdown
                    onChange={handleStatusChange}
                    title="Select to Liveshot status"
                    classNamePrefix="ls-status-dropdown"
                    className={`ls-status ${
                      buttonstyle === "readyformediareview"
                        ? "readymtc-color"
                        : buttonstyle === "notreadystyle"
                        ? "notready-color"
                        : buttonstyle === "readyforcontrolroom"
                        ? "readypcr-color"
                        : buttonstyle === "readyforairstatus"
                        ? "ready-color"
                        : buttonstyle === "clearstyle"
                        ? "clear-color"
                        : buttonstyle === "canceledstyle"
                        ? "canceled-color"
                        : ""
                    }`}
                    options={options}
                    isDisabled={
                      currentCanvas.isDefault || !updateLiveshotStatusPerm
                    }
                    value={getSelectedStatusOption()}
                  />
                </div>
                <div>
                  {noAvailVcModal && (
                    <div>
                      <Backdrop
                        show={noAvailVcModal}
                        clicked={closeAvailVcModal}
                      ></Backdrop>
                      <div className="popup-modal  errorInfo-container">
                        <div className="warning-icon-content">
                          <img
                            className="error-warning-icon"
                            src={Warning_icon_red}
                            alt="Warning_icon"
                          />
                          <span className="warning-headline">Error</span>
                          <img
                            className="Warning_Close_icon"
                            type="button"
                            alt="Warning_Close_Logo"
                            onClick={closeAvailVcModal}
                            src={
                              require("../../../assets/icons/Close-icon.svg")
                                .default
                            }
                          />
                        </div>
                        <div className="warning-content">
                          No available VC resources. Please view the Resource
                          Tracker for availability and try again.
                        </div>
                        <div className="warning-buttons">
                          <button
                            className={"error-cancel-btn"}
                            onClick={closeAvailVcModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {clearLSWarningModal && (
                    <>
                      <Backdrop
                        show={true}
                        clicked={cancelClearStatus}
                      ></Backdrop>
                      <div className="popup-modal  trashInfo-container">
                        <div className="warning-icon-content">
                          <img
                            className="warning-icon"
                            src={Warning_icon}
                            alt="Warning_icon"
                          />
                          <span className="warning-headline">Warning</span>
                        </div>
                        <div className="warning-content">
                          You have set the status to CLEAR. Moving to CLEAR will
                          un-USE and un-OWN all signals. Are you sure you want
                          to continue this action?
                        </div>
                        <div className="warning-buttons">
                          <button
                            className={"warning-cancel-btn"}
                            onClick={cancelClearStatus}
                          >
                            NO
                          </button>
                          <button
                            className={"warning-submit-btn"}
                            onClick={confirmClearStatus}
                          >
                            YES
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="lsinfo-row">
                  <div
                    className={
                      expObj
                        ? "show-lsinfo-row objectexp"
                        : "hidden-lsinfo-row objectexp"
                    }
                    id="objectexp"
                  >
                    <LabelledCheckbox
                      label={"HOST"}
                      iconSrc={UserIcon}
                      disableCheckBehaviour={!updateLiveshotStatusPerm}
                      isChecked={isHostEnabled}
                      setIsChecked={handleHostChange}
                      id={`host-checkbox-${formInfo.liveshotId}`}
                    />
                    {!isPermanentLiveshot ? (
                      <div className="exp-date-input">
                        <span className="label-datepicker">EXP: </span>
                        <Datepicker
                          selected={expiration}
                          className="disabled-input-datepicker"
                          disabled
                          dateFormat="MM/dd/yyyy"
                        />
                      </div>
                    ) : (
                      <div className="exp-date-input">
                        <span className="exp-input-overlay-ls">
                          {" "}
                          <AntdTooltip title={"EXTEND PERMANENTLY"}>
                            <i>
                              <ExtendPermIcon className="extend-perm-icon-overlay" />
                            </i>
                          </AntdTooltip>
                        </span>
                      </div>
                    )}
                    <span className="date-exp">
                      <AntdTooltip title="ADD A DAY TO EXPIRATION">
                        <Button
                          type="button"
                          btnType="expadd-date"
                          onClick={onAddObjectExpiration}
                          disabled={
                            !updateLiveshotStatusPerm ||
                            isHostEnabled ||
                            canvasTemplateSelected
                          }
                        >
                          <img src={ExtendLogo} alt="extend day icon" />
                        </Button>
                      </AntdTooltip>
                    </span>
                    <div className="exp-date-container">
                      <Datepicker
                        className="exp-date"
                        onInputClick={() =>
                          !isHostEnabled &&
                          updateLiveshotStatusPerm &&
                          setIsCalendarOpen(true)
                        }
                        onClickOutside={() => {
                          setIsCalendarOpen(false);
                          setIsExtendActive(false);
                        }}
                        selected={expiration}
                        onChange={handleChangeDate}
                        minDate={new Date()}
                        customInput={
                          <span>
                            <AntdTooltip title={"EXTEND EXPIRATION DATE"}>
                              <CalendarIcon
                                className={`${
                                  !isCalendarOpen ||
                                  !updateLiveshotStatusPerm ||
                                  isHostEnabled ||
                                  canvasTemplateSelected
                                    ? "date-picker-icon"
                                    : "date-picker-selected"
                                } ${
                                  !updateLiveshotStatusPerm ||
                                  isHostEnabled ||
                                  canvasTemplateSelected
                                    ? "restricted"
                                    : ""
                                }`}
                              />
                            </AntdTooltip>
                          </span>
                        }
                        disabled={
                          !updateLiveshotStatusPerm ||
                          isHostEnabled ||
                          canvasTemplateSelected
                        }
                        popperPlacement="bottom-start"
                      >
                        <div className="objectexpextend">
                          <Button
                            type="button"
                            btnType="extend-permanetly-btn"
                            onClick={onExtendPermanetly}
                            disabled={
                              !updateLiveshotStatusPerm || isHostEnabled
                            }
                          >
                            <i>
                              <ExtendPermIcon className={"extend-perm-icon"} />
                            </i>
                            <span className="extend-perm-text">
                              EXTEND PERMANENTLY
                            </span>
                          </Button>
                        </div>
                      </Datepicker>
                    </div>
                  </div>
                  <div className="alignbtn">
                    <div className="info">
                      <Tooltip
                        message={updateInfo}
                        position="bottom"
                        title="Update Info"
                        messageTheme="light-theme"
                        titleTheme="light-theme"
                      >
                        <InfoLiveShotLogo className="update-info-icon" />
                      </Tooltip>
                    </div>
                    <Fragment>
                      <Button
                        disabled={notAllowTakeAll}
                        type="button"
                        btnType={`useButton`}
                        onClick={() => handleUseButton()}
                      >
                        USE
                      </Button>
                      <Button
                        disabled={notAllowGrabAll}
                        type="button"
                        btnType={"ownButton"}
                        onClick={() => handleOwnButton()}
                      >
                        OWN
                      </Button>
                    </Fragment>
                  </div>
                </div>
                <div
                  className={
                    liveShotInfoWrap
                      ? "row lsinfo-dropdown liveshotinfowrap"
                      : "hidden row lsinfo-dropdown liveshotinfowrap"
                  }
                >
                  <div className="liveshotinfo col-sm-3" id="liveshotinfo">
                    <div className="window-detail-leftpnl field-r">
                      <div>
                        <div className="input-label">Start Time:</div>
                        <DebounceInput
                          debounceTimeout={1500}
                          type="text"
                          placeholder="HH:MM AM/PM"
                          className={
                            validStartTime ? "time-error-left" : "window-time"
                          }
                          value={windowStartTime ? windowStartTime : ""}
                          onChange={onWindowStartTimeChange}
                          disabled={!updateLiveshotStatusPerm}
                        />
                      </div>
                      <div>
                        <div className="input-label">End Time:</div>
                        <DebounceInput
                          debounceTimeout={1500}
                          type="text"
                          placeholder="HH:MM AM/PM"
                          className={
                            validEndTime ? "time-error-left" : "window-time"
                          }
                          onChange={onWindowEndTimeChange}
                          value={windowEndTime ? windowEndTime : ""}
                          disabled={!updateLiveshotStatusPerm}
                        />
                      </div>
                      <div>
                        <div className="input-label">Hit Time:</div>
                        <DebounceInput
                          type="text"
                          debounceTimeout={1500}
                          placeholder="HH:MM AM/PM"
                          className={
                            validHitTime ? "time-error-left" : "hit-time"
                          }
                          onChange={onHitTimeChange}
                          value={hitTime ? hitTime : ""}
                          disabled={!updateLiveshotStatusPerm}
                        />
                      </div>
                    </div>
                    <div className="talent-detail-leftpnl field">
                      <div className="input-label">Talent Name or Slug</div>
                      <DebounceInput
                        className={`talentNameInput`}
                        type="text"
                        debounceTimeout={500}
                        placeholder="ENTER TALENT NAME OR SLUG"
                        name="liveshotName"
                        onChange={onChangeLiveShotName}
                        value={liveshotName || ""}
                        autoComplete="off"
                        disabled={!updateLiveshotStatusPerm}
                        maxLength={Constants.INPUT_MAX_LENGTH}
                      />
                    </div>
                    {selectedNamelist}
                    <StudioTruck
                      formInfo={formInfo}
                      key={studioTruckData.stId}
                      studioId={stId ? stId : formInfo.stId}
                      lsId={!newliveshot ? formInfo.liveshotId : null}
                      stName={venueName}
                      stPhone={venuePhone}
                      stEmail={venueEmail}
                      studioCost={venueCost}
                      stContact={venueContact}
                      stDesc={venueNotes}
                      studioLocation={studioLocation}
                      venueCity={venueCity}
                      venueRegion={venueRegion}
                      venueCountry={venueCountry}
                      venueSkype={venueSkype}
                      venueQuicklink={venueQuicklink}
                      venueWebOther={venueWebOther}
                      roomCore={roomCore}
                      stIFB={studioIFB}
                      stPL={studioPL}
                      stPHO={studioPHO}
                      studioDefaultTransmissionPath={
                        studioDefaultTransmissionPath
                      }
                      venueType={venueType}
                      type={stype}
                      showVenue={showVenue}
                      tempVType={tempVType}
                      liveshotTypeId={liveshotTypeId}
                      liveshotNotes={liveshotNotes}
                      stTypeId={stTypeId}
                      read={readOnly}
                      hideVenueTypes={hideVenueTypes}
                      onChangeVenueType={onChangeVenueType}
                      handleOnClear={handleOnClear}
                      onChangeType={onChangeType}
                      onChangeCity={onChangeCity}
                      onChangeRegion={onChangeRegion}
                      onChangeCountry={onChangeCountry}
                      onChangeSkype={onChangeSkype}
                      onChangeQuicklink={onChangeQuicklink}
                      onChangeWebOther={onChangeWebOther}
                      onChangeTxpath={onChangeTxpath}
                      onStudioVenueNameChange={onStudioVenueNameChange}
                      onChangeVenueContactName={onChangeVenueContactName}
                      onChangeVenueContactPhone={onChangeVenueContactPhone}
                      onChangeVenueContactEmail={onChangeVenueContactEmail}
                      permission={!updateLiveshotStatusPerm}
                      onChangeContactDetails={onChangeContactDetails}
                    />
                    <div className="liveshot-notes-leftpnl field">
                      <span className="input-label">Live Shot Notes </span>
                      <DebounceInput
                        className="studioTruckNotes"
                        element="textarea"
                        name="liveshotNote"
                        debounceTimeout={1500}
                        rows="5"
                        placeholder="Transfer to other canvases."
                        value={liveshotNotes ? liveshotNotes : ""}
                        onChange={(e) => onChangeLiveshotNotes(e)}
                        disabled={!updateLiveshotStatusPerm}
                      />
                    </div>
                    <div className="hit-notes-leftpnl field">
                      <span className="input-label">Hit Notes </span>
                      <DebounceInput
                        className="studioTruckNotes"
                        element="textarea"
                        maxLength={Constants.INPUT_MAX_LENGTH}
                        debounceTimeout={1500}
                        rows="3"
                        name="hitNote"
                        placeholder="Applies to this canvas only."
                        value={hitNote ? hitNote : ""}
                        onChange={(e) => onChangeHitNotes(e)}
                        disabled={!updateLiveshotStatusPerm}
                      />
                    </div>
                    <div className="po-number-leftpnl field">
                      <span className="input-label">PO Number </span>
                      <div className="po-div">
                        <DebounceInput
                          className={`poNumberInput`}
                          type="text" // Change to text to use maxLength
                          debounceTimeout={1500}
                          maxLength={15}
                          name="poNumber"
                          value={poNo ? poNo : ""}
                          onPaste={(event) => {
                            const value =
                              event.clipboardData &&
                              event.clipboardData.getData("text/plain");
                            if (!/^[0-9]+$/.test(value)) {
                              event.preventDefault();
                            }
                          }}
                          onKeyDown={(evt) => {
                            if (
                              !/^[0-9\b]+$/.test(evt.key) &&
                              ![
                                "Backspace",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                              ].includes(evt.key) &&
                              !((evt.metaKey || evt.ctrlKey) && evt.key === "v")
                            ) {
                              evt.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            let newValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            ); // Remove any non-numeric characters
                            if (newValue.length > 15) {
                              newValue = newValue.slice(0, 15);
                            }
                            onChangePoNumber({
                              ...e,
                              target: { ...e.target, value: newValue },
                            });
                          }}
                          disabled={!updateLiveshotStatusPerm}
                        />
                      </div>
                    </div>
                    <div className="submit-btn-div">
                      <button
                        type="button"
                        className={"submit-btn"}
                        onClick={submitHandler}
                        disabled={
                          isSubmitDisabled ||
                          !checkPermission(
                            currentUserRole,
                            Constants.CREATE_LIVESHOT_SIGNAL
                          )
                        }
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                  <div className="mtcdetail-container col-sm-9">
                    <LiveShotSignals
                      isDisable={isDisable}
                      readOnly={readOnly}
                      liveShotId={!newliveshot ? formInfo.liveshotId : 0}
                      abstractLiveshotId={
                        !newliveshot
                          ? formInfo.abstractLiveshot.abstractLiveshotId
                          : 0
                      }
                      onChangeSignal={onChangeSignal}
                      formInfo={formInfo}
                      liveshotStatus={liveshotStatus}
                      ifbPin={ifbPin}
                      plPin={plPin}
                      phoPin={phoPin}
                      triggerAvailVcInitState={triggerAvailVcInitState}
                      onChangePinFromSignals={changePinFromSignals}
                      onResetAllPin={resetAllPin}
                      defaultCommSignals={defaultCommSignals}
                      resetDefaultCommSignal={resetDefaultCommSignal}
                      handlePinCommsSync={handlePinCommsSync}
                      liveShotVenueType={venueType}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCanvasId: state.canvasInfo.currentCanvasId,
    currentCanvas: state.canvasInfo.currentCanvas,
    routingCapabilityType: state.canvasInfo.routingCapabilityType,
    isFold: state.liveShotContainer.isFold,
    token: state.auth.accessToken,
    studioTruckData: state.studioTruckInfo.studioTruckData,
    successMsg: state.studioTruckOperations.successMsg,
    studioTruckList: state.studioTruckInfo.studioTruckList,
    currentUser: state.auth.currentUser,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    grabbedSignals: state.signalsInfo.grabbedSignals,
    userList: state.user.userList,
    cityListData: state.searchListInfo.cityList,
    pinModalInfo: state.routerSourceDestination.pinModalInfo,
    signalErrorMsg: state.signalsInfo.signalErrorMsg,
    showSignalErrorModal: state.signalsInfo.showModal,
    globalPins: state.signalsInfo.globalPins,
    controlRoomList: state.canvasInfo.controlRoomList,
    pinError: state.signalsInfo.pinError,
    isArmRouting: state.canvasInfo.armRouting,
    activeLiveshot: state.canvasInfo.activeLiveshot,
    normalLiveShots: state.liveShotContainer.normalLiveShots,
    inBoundCRPath: state.signalsInfo.inBoundCRPath,
    inBoundGlobalPath: state.signalsInfo.inBoundGlobalPath,
    outBoundCRPath: state.signalsInfo.outBoundCRPath,
    outBoundGlobalPath: state.signalsInfo.outBoundGlobalPath,
    inBoundExtendGlobalPath: state.signalsInfo.inBoundExtendGlobalPath,
    sharedInBoundGlobalPath: state.signalsInfo.sharedInBoundGlobalPath,
    sharedOutBoundGlobalPath: state.signalsInfo.sharedOutBoundGlobalPath,
    availSignal: state.signalsInfo.signal,
    sharedIFBGlobalPath: state.signalsInfo.sharedIFBGlobalPath,
    sharedPLGlobalPath: state.signalsInfo.sharedPLGlobalPath,
    sharedPHOTXGlobalPath: state.signalsInfo.sharedPHOTXGlobalPath,
    ifbGlobalPath: state.signalsInfo.ifbGlobalPath,
    plGlobalPath: state.signalsInfo.plGlobalPath,
    phoGlobalPath: state.signalsInfo.phoGlobalPath,
    phoTxGlobalPath: state.signalsInfo.phoTxGlobalPath,
    redBorder: state.signalsInfo.redBorder,
    ifbCRPath: state.signalsInfo.ifbCRPath,
    plCRPath: state.signalsInfo.plCRPath,
    phoCRPath: state.signalsInfo.phoCRPath,
    phoTxCRPath: state.signalsInfo.phoTxCRPath,
    pressedKey: state.canvasInfo.pressedKey,
    routerDestinations: state.routerSourceDestination.routerDestinations,
    nextActiveLiveshot: state.canvasInfo.nextActiveLiveshot,
    pinTableInfo: state.routerSourceDestination.pinTableInfo,
    sipState: state.signalsInfo.sipState,
    formattedRouterSource: state.routerSourceDestination.formattedRouterSource,
    loading: state.routerSourceDestination.loading,
    canvasTemplateSelected: state.canvasInfo.canvasTemplateSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateLiveShot: (currentCanvas, liveshotId, formData, token) =>
      dispatch(
        actions.updateLiveShot(currentCanvas, liveshotId, formData, token)
      ),
    onUpdateLiveShotOnChange: (liveshotId, formData, token) =>
      dispatch(actions.updateLiveShotOnChange(liveshotId, formData, token)),
    onPatchStudioTruck: (studioFormData, token, stId) =>
      dispatch(actions.patchStudioTruck(studioFormData, token, stId)),
    onSetAccessPin: (token, path, pin) =>
      dispatch(actions.setAccessPin(token, path, pin)),
    fetchPinModalInfo: (token) => dispatch(actions.getPinModalInfo(token)),
    onResetPinError: () => dispatch(actions.resetPinError()),
    onUpdateStatus: (currentCanvas, liveshotId, formData, token) =>
      dispatch(
        actions.updateStatus(currentCanvas, liveshotId, formData, token)
      ),
    onSaveCurrentLiveShot: (canvasId, formData, token) =>
      dispatch(actions.saveCurrentLiveShot(canvasId, formData, token)),
    onSubmitAbstractLiveshot: (
      absLiveshotInfo,
      token,
      showNotificationBar,
      currentCanvasId,
      abstractLiveshotId
    ) =>
      dispatch(
        actions.submitAbstractLiveshot(
          absLiveshotInfo,
          token,
          showNotificationBar,
          currentCanvasId,
          abstractLiveshotId
        )
      ),
    onHideErrorModal: () => dispatch(actions.hideErrorModal()),
    onHideSignalErrorModal: () => dispatch(actions.hideSignalErrorModal()),
    onTakeAll: (
      token,
      userId,
      userFullName,
      abSignalInfo,
      normalSignalInfo,
      liveshotId,
      roomId,
      currentRoom,
      crPathMsg,
      liveShotSignalSelected
    ) =>
      dispatch(
        actions.takeAll(
          token,
          userId,
          userFullName,
          abSignalInfo,
          normalSignalInfo,
          liveshotId,
          roomId,
          currentRoom,
          crPathMsg,
          liveShotSignalSelected
        )
      ),
    onGrabAll: (
      token,
      userId,
      userFullName,
      outBoundFieldsNormal,
      commsFieldsNormal,
      signalsArr,
      roomId,
      currentRoom,
      crPathMsg,
      liveShotSignalSelected,
      currentCanvas
    ) =>
      dispatch(
        actions.grabAll(
          token,
          userId,
          userFullName,
          outBoundFieldsNormal,
          commsFieldsNormal,
          signalsArr,
          roomId,
          currentRoom,
          crPathMsg,
          liveShotSignalSelected,
          currentCanvas
        )
      ),
    onLiveshotUpdate: (
      updatedBy,
      currentCanvasId,
      liveshot_id,
      payload,
      token,
      refetchCanvas
    ) =>
      dispatch(
        actions.updateLiveshot(
          updatedBy,
          currentCanvasId,
          liveshot_id,
          payload,
          token,
          refetchCanvas
        )
      ),
    onDeleteLiveShot: (liveshot_id, canvas_id, userId, token) =>
      dispatch(actions.deleteLiveShot(liveshot_id, canvas_id, userId, token)),
    onSearchCurrentLiveShot: (token) => dispatch(actions.searchLiveShot(token)),
    onDropSip: (token, sip) => dispatch(actions.dropSummarySIP(token, sip)),
    resetSignals: (token, signals, canvasId) =>
      dispatch(actions.resetSignals(token, signals, canvasId)),
    onResetStudioTruckData: () => dispatch(actions.resetStudioTruckData()),
    showCommonNotificationBar: (notificationDetails) =>
      dispatch(actions.showNotificationBar(notificationDetails)),
    onResetAddedFromSearch: () => dispatch(actions.resetAddedFromSearch()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HostStudioObject);
