export const timeInHours = (time) => {
  let flagTime = null;
  const splitTime = time.split(" ");
  const splitHour = splitTime[0].split(":");
  if (splitTime[1] === "PM" && splitHour[0] < "12") {
    flagTime = parseInt(splitHour[0]) + 12;
    return flagTime + ":" + splitHour[1];
  } else if (splitTime[1] === "AM" && splitHour[0] === "12") {
    splitHour[0] = "00";
    return splitHour[0] + ":" + splitHour[1];
  } else {
    return splitTime[0];
  }
};

export const timeNight = (time) => {
  let flagTime = null;
  const splitTime = time.split(" ");
  const splitHour = splitTime[0].split(":");
  if (splitTime[1] === "PM" && splitHour[0] < "12") {
    flagTime = parseInt(splitHour[0]) + 12;
    return flagTime + ":" + splitHour[1];
  } else if (splitTime[1] === "AM" && splitHour[0] < "12") {
    splitHour[0] = parseInt(splitHour[0]) + 24;
    return splitHour[0] + ":" + splitHour[1];
  } else if (splitTime[1] === "AM" && splitHour[0] === "12") {
    splitHour[0] = parseInt(splitHour[0]) + 12;
    return splitHour[0] + ":" + splitHour[1];
  }
};

export const changeTimeInHours = (time, startTime) => {
  let flagTime = null;
  const splitTime = time.split(" ");
  const splitHour = splitTime[0].split(":");
  const splitShowTime = startTime.split(" ");
  const splitShowHour = splitShowTime[0].split(":");
  if (splitTime[1] === "PM" && splitHour[0] < "12") {
    flagTime = parseInt(splitHour[0]) + 12;
    return flagTime + ":" + splitHour[1];
  } else if (splitTime[1] === "AM" && splitHour[0] === "12") {
    //splitHour[0] = "00";
    splitHour[0] = parseInt(splitHour[0]) + 12;
    return splitHour[0] + ":" + splitHour[1];
  } else if (
    splitTime[1] === "AM" &&
    splitHour[0] < "12" &&
    splitShowTime[1] === "PM" &&
    parseInt(splitShowHour[0]) >= 9
  ) {
    splitHour[0] = parseInt(splitHour[0]) + 24;
    return splitHour[0] + ":" + splitHour[1];
  } else {
    return splitTime[0];
  }
};

export const dateConversion = (str) => {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

export const currentDate = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

export const removeDuplicates = (signals) => {
  let newArray = [];
  let uniqueObject = {};
  for (let i in signals) {
    let objTitle = signals[i]["liveshotSignalId"];
    uniqueObject[objTitle] = signals[i];
  }
  for (let i in uniqueObject) {
    newArray.push(uniqueObject[i]);
  }
  return newArray;
};

export const DateFormater = (date) => {
  if (date === null) return;
  let Time = null;
  Time = new Date(date);
  return Time.toLocaleTimeString(Time, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  });
};

export const DateFormaterTime = (date) => {
  if (date === null) return;
  let Time = null;
  Time = new Date(date);
  let valueHours = Time.toLocaleTimeString(Time, {
    hour: "numeric",
    hour12: false,
  });
  let valueMinutes = Time.toLocaleTimeString(Time, {
    minute: "numeric",
    hour12: false,
  });
  let valueSeconds = Time.toLocaleTimeString(Time, {
    second: "numeric",
    hour12: false,
  });
  var target = +valueHours * 60 * 60 + +valueMinutes * 60 + +valueSeconds;
  return target;
};

export const convertTimeFormat = (time) => {
  let hours = time.toDate().getHours();
  let minutes = time.toDate().getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const interactiveText = (height, element) => {
  const txtHeight = height;
  const txtLS = document.getElementsByClassName(`${element}`);
  for (let i = 0; i < txtLS.length; i++) {
    if (txtLS[i].value == "") {
      txtLS[i].setAttribute(
        "style",
        "height:" + txtHeight + "px;overflow-y:hidden;"
      );
    } else {
      txtLS[i].setAttribute(
        "style",
        "height:" + txtLS[i].scrollHeight + "px;overflow-y:hidden;"
      );
    }
    txtLS[i].addEventListener("input", OnInputLSNotes, false);
  }
  function OnInputLSNotes(e) {
    this.style.height = 0;
    this.style.height = this.scrollHeight + "px";
  }
};
// Function to replace null values with empty strings.
export const replaceNullWithEmptyString = (ArrayOfObjects) => {
  return ArrayOfObjects.map((obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = value === null ? "" : value;
      return acc;
    }, {});
  });
};

export const hasValidPath = (fields, name, list) => {
  let invalid_path = false;
  if (!fields[name] || !list.includes(fields[name])) {
    invalid_path = true;
  }
  fields[`${name}Error`] = invalid_path;
  return invalid_path;
};

export const getPathClassName = (hasInvalidPath, liveshotInfo = true) => {
  return hasInvalidPath && liveshotInfo ? " path-error" : "";
};

export const getColValue = (searchInfo, key = "", crList = []) => {
  let newArray =
    searchInfo && Array.isArray(searchInfo[key])
      ? searchInfo[key].filter((val) => val).slice(0, 3)
      : [];
  if (crList.length > 0 && newArray.length > 0) {
    newArray = newArray.reduce((accumulator, element) => {
      return [...accumulator, splitSignalAndPhone(element, "", crList)[0]];
    }, []);
  }
  return newArray.join("\n");
};

export const splitSignalAndPhone = (pathName, type, crList = []) => {
  var typicalPatt = /^(ifb|pl) [0-9a-z][0-9a-z]\d\d /i;
  var occasionalPatt = /^(ifb|pl) \d[0-9a-z]/i;
  var generalPatt = /^(ph)\d\d/i;
  var anotherPatt = /^(ifb|pl) (i|p)\d[0-9a-z][0-9a-z]/i;
  var checkPattern = /^CR/i;
  var elements = [];
  if (
    crList.length > 0 &&
    hasValidPath({ pathName: pathName }, "pathName", crList)
  ) {
    return [pathName];
  }
  if (pathName !== null && checkPattern.test(pathName)) {
    if (pathName.includes("PH0") || pathName.includes("PHO")) {
      let words = pathName.split(" ");
      for (let i = 0; i < words.length; i++) {
        if (words[i].includes("PHO") || words[i].includes("PH0")) {
          let pho = words[i];
          if (i < words.length - 1) {
            pho = pho + " " + words[++i];
          }
          elements.push(pho);
        }
      }
    }
    if (pathName.match(typicalPatt)) {
      elements.push(pathName.match(typicalPatt)[0].trim());
      elements.push(pathName.replace(typicalPatt, ""));
    } else if (pathName.match(occasionalPatt)) {
      elements.push(pathName.match(occasionalPatt)[0].trim());
      elements.push(pathName.replace(occasionalPatt, ""));
    } else if (pathName.match(generalPatt)) {
      elements.push(pathName.match(generalPatt)[0].trim());
      elements.push(pathName.replace(generalPatt, ""));
    } else if (pathName.match(anotherPatt)) {
      elements.push(pathName.match(anotherPatt)[0].trim());
      elements.push(pathName.replace(anotherPatt, ""));
    } else if (pathName.includes("IFB")) {
      let words = pathName.split(" ");
      for (let i = 0; i < words.length; i++) {
        if (words[i] === "IFB") {
          let ifb = words[i];
          if (i < words.length - 1) {
            ifb = ifb + " " + words[++i];
          }
          elements.push(ifb);
        }
      }
    } else if (pathName.includes("PL")) {
      let words = pathName.split(" ");
      for (let i = 0; i < words.length; i++) {
        if (words[i] === "PL") {
          let ifb = words[i];
          if (i < words.length - 1) {
            ifb = ifb + " " + words[++i];
          }
          elements.push(ifb);
        }
      }
    }
  } else {
    elements.push(pathName);
  }
  return elements;
};

export const validateAndUpdatePin = (value, updatePin) => {
  const validPattern = /^[0-9\b]+$/;
  if (value === "" || validPattern.test(value)) {
    updatePin(value);
  }
};

export const validateFormPin = (formData) => {
  const validationFields = [
    {
      key: "studioIfb1pin",
      message: "Please enter a valid 4 digit IFB 1 PIN to continue.",
    },
    {
      key: "studioIfb2pin",
      message: "Please enter a valid 4 digit IFB 2 PIN to continue.",
    },
    {
      key: "studioPlpin",
      message: "Please enter a valid 4 digit PL PIN to continue.",
    },
  ];
  let errorMsg = null;

  validationFields.some(({ key, message }) => {
    if (!(formData[key] ? formData[key].toString().length === 4 : true)) {
      errorMsg = message;
      return true;
    }
    return false;
  });

  return errorMsg;
};

export const getActiveSipClass = (sipState, value = "", permission) => {
  return sipState[value]
    ? `comms-labels-summary-circle green-btn ${
        permission ? "allowed" : "restricted"
      }`
    : "comms-labels-summary-circle";
};

export const getSipLabel = (sipValue) => {
  return typeof sipValue === "object"
    ? !sipValue.sip && !sipValue.pin
      ? "----"
      : `${sipValue.sip ? sipValue.sip : "----"}/${
          sipValue.pin ? sipValue.pin : "----"
        }`
    : sipValue;
};

export const getSipPill = (sipValue, key) => {
  return typeof sipValue === "object"
    ? key === "pathName"
      ? (sipValue[key] || "").substring(0, 8)
      : sipValue[key]
    : "";
};

export const getSummaryRow = (summaryPath = [], key) => {
  let elements = [];
  summaryPath.map((val) => {
    elements.push(val[key] || "");
  });
  return elements.join("\n");
};

export const sortBySignalId = (list) => {
  return list.sort((a, b) => {
    return a.liveshotSignalTypeId - b.liveshotSignalTypeId;
  });
};

export const transformInput = (value = "") => {
  return (value || "").toUpperCase().trim();
};

export const parseDate = (value = "") => {
  const dateObj = value ? new Date(value) : new Date();
  //Appending Time T05:59:59Z to make liveshot obj to expire at 1 AM EST
  return dateObj.toISOString().split("T")[0] + "T05:59:59Z";
};

export const getContactList = (studioList, stName = "", stId) => {
  let selectedSt = studioList.filter((el) => {
    return el.studioName === stName && el.stId == stId;
  });
  const contacts = new Map();

  if (selectedSt.length > 0) {
    let studioTruckData = selectedSt[0];
    const contactKeys = ["studioContact", "studioPhone", "studioEmail"];

    for (let i = 1; i <= 3; i++) {
      const iterateKey = i === 1 ? "" : i;
      let contactName = studioTruckData[contactKeys[0] + iterateKey] || "";
      const parseContactName = contactName
        .toLowerCase()
        .trim()
        .replace(/\t/i, " ");
      if (contactName) {
        contacts.set(
          parseContactName,
          contactKeys.reduce((accumulator, value) => {
            return {
              ...accumulator,
              [value]: studioTruckData[value + iterateKey] || "",
            };
          }, {})
        );
      }
    }
  }

  return contacts;
};

export const trimLabel = (text = "", len = 40) => {
  return (text || "").substring(0, len);
};
