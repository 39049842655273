import React, { useEffect } from "react";
import "./Backdrop.css";

const Backdrop = (props) => {
  useEffect(() => {
    // Disable root container scrolling when modal is open
    const rootElm = document.getElementsByTagName("body")[0];
    if (props.show) {
      rootElm.classList.add("hide-scroll");
    } else {
      rootElm.classList.remove("hide-scroll");
    }
    return () => {
      rootElm.classList.remove("hide-scroll");
    };
  }, [props.show]);

  return props.show ? (
    <div className="Backdrop" onClick={props.clicked}></div>
  ) : null;
};

export default Backdrop;
