import React from "react";
import "./SignalModal.css";
import routesInfo from "../../../assets/icons/routes-info.svg";
import routeSuccessInfo from "../../../assets/icons/routes-success-info.svg";
import routeFailedInfo from "../../../assets/icons/routes-failed-info.svg";
import routesDuplicateInfo from "../../../assets/icons/routes-duplicate-info.svg";
import CloseIconComponent from "../../shared/UI/CloseIconComponent/CloseIconComponent";
import commonSuccessInfo from "../../../assets/icons/common-success-info.svg";
import Backdrop from "../../shared/UI/Backdrop/Backdrop";
import Warning_icon from "../../../assets/icons/Warning.svg";
import warning_icon_red from "../../../assets/icons/warning-red.svg";
import "../../shared/UI/ConfirmationModal/ConfirmationModalPopup.css";

const SignalModal = (props) => {
  const OUTBOUND_SIGNALTYPE = "outbound";
  const COMMS_SIGNALTYPE = "comms";

  let msg = "";
  let headerInfo = "";
  let headerIcon = "";

  let isDuplicate = props.signalInfo.isDuplicate;
  let isSuccess = [200, 202].includes(props.signalInfo.status);
  let isFailed = [500, 409, 404, 400].includes(props.signalInfo.status);
  let signalType = props.signalInfo.signalType;

  const getFooterBtn = (className = "venue-save-icon") => {
    return (
      <input
        className={className}
        type="button"
        value="Close"
        onClick={props.closeModal}
      />
    );
  };

  let footerButtons = getFooterBtn();

  const handleClose = (skipDuplicate = false) => {
    if (skipDuplicate) {
      confirmRoute(true);
    } else {
      props.closeModal();
    }
  };
  const handleOverrideNo = (skipOverride = false) => {
    props.closeModal();
    if (skipOverride) {
      overrideCanvasSignal(true);
    } else {
      props.closeModal();
    }
  };
  const overrideCanvasSignal = (skipOverride = false) => {
    props.closeModal();

    let crPath = props.signalInfo.crPath;
    delete props.signalInfo.crPath;
    props.signalInfo.overrideCanvasSignal(
      props.signalInfo,
      crPath,
      skipOverride
    );
  };

  //Function to trigger callback to overwrite the existing localpath.
  const confirmRoute = (skipDuplicate = false) => {
    props.closeModal();

    delete props.signalInfo.isDuplicate;
    let crPath = props.signalInfo.crPath;
    delete props.signalInfo.crPath;
    let btnType = props.signalInfo.btnType;
    delete props.signalInfo.btnType;
    let isUse = props.signalInfo.isUse;
    delete props.signalInfo.isUse;

    if (skipDuplicate) {
      props.signalInfo &&
        props.signalInfo.confirmDuplicateRoute(
          props.signalInfo,
          crPath,
          btnType,
          skipDuplicate,
          isUse
        );
    } else {
      props.signalInfo &&
        props.signalInfo.confirmDuplicateRoute(
          props.signalInfo,
          crPath,
          btnType,
          skipDuplicate,
          isUse
        );
    }
  };
  // scenario when 202 code in 'own' a signal
  if (isSuccess && props.signalInfo.control === "unOwn") {
    headerIcon = (
      <>
        <img className="modalIcon" src={Warning_icon} alt="Plus Logo" />
      </>
    );
    msg = (
      <>
        <p className="textInfo">{`Local Path: ${props.signalInfo.crPathName} ${
          props.signalInfo.globalPathName
            ? `and Global Path: ${props.signalInfo.globalPathName} `
            : ""
        } route has been removed.`}</p>
      </>
    );
    headerInfo = "Warning";
    footerButtons = getFooterBtn("duplicate-close-btn");
  }
  // scenario when 202 code in 'own' a signal
  if (isSuccess && props.signalInfo.control === "grab") {
    headerIcon = (
      <>
        <img className="modalIcon" src={routeSuccessInfo} alt="Plus Logo" />
      </>
    );
    msg = (
      <>
        <p className="textInfo">{`Local Path: ${props.signalInfo.crPathName} ${
          props.signalInfo.globalPathName
            ? `and Global Path: ${props.signalInfo.globalPathName} `
            : ""
        }are routed.`}</p>
      </>
    );
    headerInfo = "Route Successful";
  }
  // scenario when 500,409 code in 'own' a signal
  if (isFailed && props.signalInfo.control === "grab") {
    headerIcon = (
      <>
        <img className="modalIcon" src={routeFailedInfo} alt="Plus Logo" />
      </>
    );
    if (props.signalInfo.status === 409) {
      let pathName =
        props.signalInfo.signalType === OUTBOUND_SIGNALTYPE
          ? props.signalInfo.globalPathName
          : props.signalInfo.crPathName;
      let errorResponseData = props.signalInfo.response.data;
      let showName = errorResponseData.show.showName;
      let liveShotDetails = errorResponseData.liveshot;
      let startTime = liveShotDetails.windowStartTime;
      let endTime = liveShotDetails.windowEndTime;
      let canvasShowDate = errorResponseData.canvas.showDate;
      let dateParts = canvasShowDate.split("-");
      let rearrangedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
      canvasShowDate = rearrangedDate;

      msg = (
        <>
          <p className="textInfo marginBottom15Px">{`${pathName} is already owned on another canvas. ${showName} on ${canvasShowDate} is using ${pathName} from ${startTime} to ${endTime}.`}</p>

          <p className="textInfo">{`If you would like to OWN this signal, please confirm that you would like to un-OWN the signal from ${showName}.`}</p>
        </>
      );
      headerInfo = "Error";
      footerButtons = (
        <div className="warning-buttons">
          <button
            className="error-cancel-btn"
            onClick={() => {
              let skipOverride = props.signalInfo.isSkipOverride;
              handleOverrideNo(skipOverride);
            }}
          >
            NO
          </button>
          <button
            className="error-close-btn"
            onClick={() => overrideCanvasSignal()}
          >
            YES
          </button>
        </div>
      );
    } else {
      msg = (
        <>
          <p className="textInfo">{`Local Path: ${
            props.signalInfo.crPathName
          } ${
            props.signalInfo.globalPathName
              ? `and Global Path: ${props.signalInfo.globalPathName} `
              : ""
          }are not routed.`}</p>
        </>
      );
      headerInfo = "Route Failed";
      footerButtons = getFooterBtn("error-close-btn");
    }
  }

  // scenario for 'own' signal in canvas/block/summary
  if (isSuccess && props.signalInfo.control === "takeAll") {
    headerIcon = (
      <>
        <img className="modalIcon" src={commonSuccessInfo} alt="Plus Logo" />
      </>
    );
    msg = (
      <>
        {props.signalInfo.signals.map((signal, index) => {
          return (
            <div className="textMsg" key={"status-ms" + index}>
              <span
                className={
                  "statusChip " +
                  ([200, 202].includes(signal.status)
                    ? "success-chip"
                    : "failure-chip")
                }
              >
                {[200, 202].includes(signal.status) ? "ROUTED" : "FAILED"}
              </span>
              <span className="textInfo">{`Local Path: ${signal.crPathName} ${
                signal.globalPathName
                  ? `and Global Path: ${signal.globalPathName}`
                  : ""
              }`}</span>
            </div>
          );
        })}
      </>
    );
    headerInfo = "Routes Info";
  }
  // scenario for 'own' signal in canvas/block/summary
  if (isSuccess && props.signalInfo.control === "grabAll") {
    headerIcon = (
      <>
        <img className="modalIcon" src={commonSuccessInfo} alt="Plus Logo" />
      </>
    );
    msg = (
      <>
        {props.signalInfo.signals.map((signal, index) => {
          return (
            <div className="textMsg" key={"status-ms" + index}>
              <span
                className={
                  "statusChip " +
                  ([200, 202].includes(signal.status)
                    ? "success-chip"
                    : "failure-chip")
                }
              >
                {[200, 202].includes(signal.status) ? "ROUTED" : "FAILED"}
              </span>
              {/* <span className="textInfo">{`Local Path: ${signal.crPathName} ${
                signal.globalPathName
                  ? `and Global Path: ${signal.crPathName}`
                  : ""
              }`}</span> */}
              <span className="textInfo">{`${
                signal.crPathName ? `Local Path: ${signal.crPathName}` : ""
              } ${
                signal.globalPathName
                  ? `and Global Path: ${signal.globalPathName}`
                  : ""
              }`}</span>
            </div>
          );
        })}
      </>
    );
    headerInfo = "Routes Info";
  }

  // scenario when 500 code in 'use' a canvas signals
  if (
    props.signalInfo.status === 500 &&
    props.signalInfo.control === "takeAll"
  ) {
    msg = props.signalInfo.signals.map((field, idx) => {
      let taken = field.status === 500 ? "not" : "";
      return (
        <>
          <p
            className={field.status === 500 ? "text-danger" : "taken"}
            key={idx}
          >{`CR Path: ${field.crPathName} and Global Path: ${field.globalPathName} have ${taken} taken`}</p>
          <p className="text-danger">
            Error status code: {props.signalInfo.status}
          </p>
          <p className="text-danger">Message: {props.signalInfo.msg}</p>
        </>
      );
    });
  }
  // scenario when 500 code in 'own' a canvas signals
  if (
    props.signalInfo.status === 500 &&
    props.signalInfo.control === "grabAll"
  ) {
    msg = props.signalInfo.signals.map((field, idx) => {
      let grabbed = field.status === 500 ? "not" : "";
      let text = field.liveshotSignalTypeId !== 1 ? "grabbed" : "taken";
      // STATUS AND MESSAGE FROM THE API
      return (
        <>
          <p
            className={field.status === 500 ? "text-danger" : "taken"}
            key={idx}
          >{`CR Path: ${field.crPathName} and Global Path: ${field.globalPathName} have ${grabbed} ${text}`}</p>
          <p className="text-danger">
            Error status code: {props.signalInfo.status}
          </p>
          <p className="text-danger">Message: {props.signalInfo.msg}</p>
        </>
      );
    });
  }
  // scenario when 200 code in 'use' a signal
  if (props.signalInfo.status === 200 && props.signalInfo.control === "take") {
    headerIcon = (
      <>
        <img className="modalIcon" src={routeSuccessInfo} alt="Plus Logo" />
      </>
    );
    msg = (
      <>
        <p className="textInfo">{`Local Path: ${props.signalInfo.crPathName} and Global Path: ${props.signalInfo.globalPathName} are routed.`}</p>
      </>
    );
    headerInfo = "Route Successful";
  }
  // scenario when 500,409 code in 'use' a signal
  if (isFailed && props.signalInfo.control === "take") {
    headerIcon = (
      <>
        <img className="modalIcon" src={routeFailedInfo} alt="Plus Logo" />
      </>
    );
    msg = (
      <>
        <p className="textInfo">{`Local Path: ${props.signalInfo.crPathName} and Global Path: ${props.signalInfo.globalPathName} are not routed.`}</p>
      </>
    );
    headerInfo = "Route Failed";
  }

  //scenario for duplicate local path
  if (props.signalInfo.isDuplicate) {
    let PathName =
      props.signalInfo.signalType === "outbound" ? "Global Path" : "Local Path";
    headerIcon = (
      <>
        <img className="modalIcon" src={routesDuplicateInfo} alt="Plus Logo" />
      </>
    );
    msg = (
      <>
        <p className="textInfo">{`${PathName}: ${
          props.signalInfo.signalType === "outbound"
            ? props.signalInfo.globalPathName
            : props.signalInfo.crPathName
        } was previously routed. Would you like to overwrite it and continue routing all signals?`}</p>
      </>
    );
    headerInfo = "Duplicate Route";
    footerButtons = (
      <div className="warning-buttons">
        <button
          className="delete-cancel-btn"
          onClick={() => {
            let skipDuplicate = props.signalInfo.isSkipDuplicate;
            handleClose(skipDuplicate);
          }}
        >
          NO
        </button>
        <button className="duplicate-submit-btn" onClick={() => confirmRoute()}>
          YES
        </button>
      </div>
    );
  }

  if (props.signalInfo.infoType === "ERROR_OWNED_SIGNAL_ROOM_CHANGE") {
    isFailed = true;

    headerIcon = (
      <>
        <img className="modalIcon" src={warning_icon_red} alt="Plus Logo" />
      </>
    );

    msg = (
      <>
        <p className="textInfo">{`Control Room cannot be updated because 
        there is an active OWN route on a Summary Row.`}</p>
      </>
    );

    headerInfo = "Error";
    footerButtons = getFooterBtn("error-close-btn");
  }

  let signalMsg = null;
  if (props.crMsg) {
    signalMsg = (
      <p className="text-danger">Signal is not available for route</p>
    );
  }

  const getCloseIconStyle = () => {
    if (isDuplicate || props.signalInfo.control === "unOwn") {
      return "signalsYellowBorder";
    } else if (
      isSuccess &&
      !isDuplicate &&
      props.signalInfo.control !== "unOwn"
    ) {
      return "signalsGreenBorder";
    } else if (isFailed && !isDuplicate) {
      return "signalsRedBorder";
    }
    return "";
  };

  return (
    props.showSignalModal && (
      <>
        <Backdrop show={props.showSignalModal} clicked={() => handleClose()} />
        <div className="popup-modal-route">
          <div
            className={`modal-content signal-msg-content-box ${getCloseIconStyle()}`}
          >
            <div className="modal-header headerDiv">
              {headerIcon}

              <p className="headerText">{headerInfo}</p>
              <CloseIconComponent
                onCloseModal={props.closeModal}
                classNames={`signals-close-icon ${
                  isFailed
                    ? "error-close-icon"
                    : isDuplicate || props.signalInfo.control === "unOwn"
                    ? "duplicate-close-icon"
                    : ""
                }`}
              />
            </div>
            <div className="modal-body signal-msg-body">
              {msg} {signalMsg}
            </div>
            <div className="modal-footer signalModalFooter">
              {footerButtons}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default SignalModal;
